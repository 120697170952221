import modules from './modules';

import {
	GET_SUBNET_LIST_START,
	GET_SUBNET_LIST_SUCCESS,
	GET_SUBNET_LIST_FAIL,

	GET_SUBNET_START,
	GET_SUBNET_SUCCESS,
	GET_SUBNET_FAIL,

	CREATE_SUBNET_START,
	CREATE_SUBNET_SUCCESS,
	CREATE_SUBNET_FAIL,

	DELETE_SUBNET_START,
	DELETE_SUBNET_SUCCESS,
	DELETE_SUBNET_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

const { API, utils, toast } = modules;

/* subnet list start */
export const getSubnetListStart = () => {
	return {
		type: GET_SUBNET_LIST_START
	};
};

export const getSubnetListSuccess = subnetList => {
	return {
		type: GET_SUBNET_LIST_SUCCESS,
		subnetList
	};
};

export const getSubnetListFail = () => {
	return {
		type: GET_SUBNET_LIST_FAIL
	};
};

export const getSubnetList = (ordering, cursor, perPage) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getSubnetListStart());
		return API.getSubnetList(userData.token, ordering, cursor, perPage)
			.then(response => {
				const subnetList = (response && response.data) || {};
				dispatch(getSubnetListSuccess(subnetList));
				return subnetList;
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				dispatch(getSubnetListFail());
				return {};
			});

	};
};
/* subnet list end */

/* subnet start */
export const getSubnetStart = () => {
	return {
		type: GET_SUBNET_START
	};
};

export const getSubnetSuccess = subnet => {
	return {
		type: GET_SUBNET_SUCCESS,
		subnet
	};
};

export const getSubnetFail = () => {
	return {
		type: GET_SUBNET_FAIL
	};
};

export const getSubnetById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getSubnetStart());
		return API.getSubnetById(userData.token, id)
			.then(response => {
				dispatch(getSubnetSuccess(response.data));
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				dispatch(getSubnetFail());
			});

	};
};
/* subnet end */

/* create/edit subnet start */
export const createSubnetStart = () => {
	return {
		type: CREATE_SUBNET_START
	};
};

export const createSubnetSuccess = subnet => {
	return {
		type: CREATE_SUBNET_SUCCESS,
		subnet
	};
};

export const createSubnetFail = () => {
	return {
		type: CREATE_SUBNET_FAIL
	};
};

export const createSubnet = (subnetData, id) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(createSubnetStart());
		return API.createSubnet(userData.token, subnetData, id)
			.then(response => {
				dispatch(createSubnetSuccess(response.data));
				toast.success(`Subnet ${(id == null) ? 'created' : 'edited'} successfully`, {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				let message = 'Something went wrong';
				const resp = (error && error.response) || '';
				if(resp.data && typeof resp.data !== 'string') {
					const errors = resp.data;
					for(var o in errors) {
						message = (errors[o] && errors[o][0]) || 'Something went wrong';
					}
				}
				utils.handleError(error, dispatch, UPDATE_USER_DATA, message);
				dispatch(createSubnetFail());
				return (error && error.response) || {};
			});

	};
};
/* create/edit subnet end */

/* delete subnet start */
export const deleteSubnetStart = () => {
	return {
		type: DELETE_SUBNET_START
	};
};

export const deleteSubnetSuccess = subnetList => {
	return {
		type: DELETE_SUBNET_SUCCESS,
		subnetList
	};
};

export const deleteSubnetFail = () => {
	return {
		type: DELETE_SUBNET_FAIL
	};
};

export const deleteSubnetById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(deleteSubnetStart());
		return API.deleteSubnetById(userData.token, id)
			.then(() => {
				let _subnetList = getState().subnet.subnetList || {};
				_subnetList.results = (_subnetList.results || []).filter(s => s.snid !== id);
				dispatch(deleteSubnetSuccess(_subnetList));
				toast.success('Subnet deleted successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return id;
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA, 'Something went wrong deleting subnet');
				dispatch(deleteSubnetFail());
			});

	};
};
/* delete subnet end */