import modules from './modules';

import {
	GET_PROVIDER_LIST_START,
	GET_PROVIDER_LIST_SUCCESS,
	GET_PROVIDER_LIST_FAIL,

	GET_PROVIDER_START,
	GET_PROVIDER_SUCCESS,
	GET_PROVIDER_FAIL,

	CREATE_PROVIDER_START,
	CREATE_PROVIDER_SUCCESS,
	CREATE_PROVIDER_FAIL,

	DELETE_PROVIDER_START,
	DELETE_PROVIDER_SUCCESS,
	DELETE_PROVIDER_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

const { API, utils, toast } = modules;

/* provider list start */
export const getProviderListStart = () => {
	return {
		type: GET_PROVIDER_LIST_START
	};
};

export const getProviderListSuccess = providerList => {
	return {
		type: GET_PROVIDER_LIST_SUCCESS,
		providerList
	};
};

export const getProviderListFail = () => {
	return {
		type: GET_PROVIDER_LIST_FAIL
	};
};

export const getProviderList = (ordering, cursor, perPage) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getProviderListStart());
		API.getProviderList(userData.token, ordering, cursor, perPage)
			.then(response => {
				const providerList = (response && response.data) || {};
				dispatch(getProviderListSuccess(providerList));
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				dispatch(getProviderListFail());
			});

	};
};
/* provider list end */

/* provider start */
export const getProviderStart = () => {
	return {
		type: GET_PROVIDER_START
	};
};

export const getProviderSuccess = provider => {
	return {
		type: GET_PROVIDER_SUCCESS,
		provider
	};
};

export const getProviderFail = () => {
	return {
		type: GET_PROVIDER_FAIL
	};
};

export const getProviderById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getProviderStart());
		return API.getProviderById(userData.token, id)
			.then(response => {
				dispatch(getProviderSuccess(response.data));
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				dispatch(getProviderFail());
			});

	};
};
/* provider end */

/* create/edit provider start */
export const createProviderStart = () => {
	return {
		type: CREATE_PROVIDER_START
	};
};

export const createProviderSuccess = provider => {
	return {
		type: CREATE_PROVIDER_SUCCESS,
		provider
	};
};

export const createProviderFail = () => {
	return {
		type: CREATE_PROVIDER_FAIL
	};
};

export const createProvider = (providerData, id) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(createProviderStart());
		return API.createProvider(userData.token, providerData, id)
			.then(response => {
				dispatch(createProviderSuccess(response.data));
				toast.success(`Provider ${(id == null) ? 'created' : 'edited'} successfully`, {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				let message = 'Something went wrong';
				const resp = (error && error.response) || '';
				if(resp.data && typeof resp.data !== 'string') {
					const errors = resp.data;
					for(var o in errors) {
						message = (errors[o] && errors[o][0]) || 'Something went wrong';
					}
				}
				utils.handleError(error, dispatch, UPDATE_USER_DATA, message);
				dispatch(createProviderFail());
				return (error && error.response) || {};
			});

	};
};
/* create/edit provider end */

/* delete provider start */
export const deleteProviderStart = () => {
	return {
		type: DELETE_PROVIDER_START
	};
};

export const deleteProviderSuccess = providerList => {
	return {
		type: DELETE_PROVIDER_SUCCESS,
		providerList
	};
};

export const deleteProviderFail = () => {
	return {
		type: DELETE_PROVIDER_FAIL
	};
};

export const deleteProviderById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(deleteProviderStart());
		return API.deleteProviderById(userData.token, id)
			.then(() => {
				let _providerList = getState().provider.providerList || {};
				_providerList.results = (_providerList.results || []).filter(n => n.pvid !== id);
				dispatch(deleteProviderSuccess(_providerList));
				toast.success('Provider deleted successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return id;
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA, 'Something went wrong deleting provider');
				dispatch(deleteProviderFail());
			});

	};
};
/* delete provider end */