import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getEntityById, createEntity } from '../../store/actions';

import Loader from '../../components/UI/Loader/Loader';
import './Entity.css';

const INITIALSTATE = {
	enid: null,
	name: '',
	client_domain: '',
	mgt_domain: '',

	errors: {},
	isTouched: {}
};

class Entity extends Component {
	state = {...INITIALSTATE};

	componentDidMount() {
		document.title = 'Entity - ' + process.env.REACT_APP_PAGE_TITLE;
		const { location: { pathname } } = this.props;
		const enid = pathname.split('/entity/')[1];
		if(enid !== 'new') {
			this.props.onGetEntityById(enid);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { location: { pathname } } = this.props;
		const enid = pathname.split('/entity/')[1];
		if(!nextProps.isCreating && nextProps.entity && nextProps.entity.enid && (enid !== 'new')) {
			const data = nextProps.entity;
			document.title = (data && (data.name + ' - ' + process.env.REACT_APP_PAGE_TITLE)) || ('Entity - ' + process.env.REACT_APP_PAGE_TITLE);
			this.setState({
				enid: (data && data.enid) || null,
				name: (data && data.name) || '',
				client_domain: (data && data.client_domain) || '',
				mgt_domain: (data && data.mgt_domain) || ''
			});
		}
	}

	handleFieldChange = event => {
		const { isTouched, errors } = this.state;
		const id = event.target.id;
		const value = event.target.value;
		const _errors = { ...errors };
		const _isTouched = {
			...isTouched,
			[id]: true
		};

		if((value + '').length === 0) {
			_errors[id] = 'required';
		} else {
			delete _errors[id];
		}

		this.setState({
			[id]: value,
			isTouched: _isTouched,
			errors: _errors
		});
	};

	handleCreateEntity = (e, redirect, openNewBlank, duplicate) => {
		const { name, client_domain, mgt_domain } = this.state;
		const { history, location: { pathname } } = this.props;
		const enid = pathname.split('/entity/')[1];
		if(name && client_domain && mgt_domain) {
			const dataToSend = {
				name,
				client_domain,
				mgt_domain
			};
			this.props.onCreateEntity(dataToSend, (enid === 'new' ? null : enid)).then(response => {
				if(response.status && response.status !== 200 && response.status !== 201) {
					return;
				}
				if(redirect) {
					history.push('/entity');
				}
				if(openNewBlank) {
					history.push('/entity/new');
					this.setState(duplicate ? {enid: null} : {...INITIALSTATE});
				}
			});
		}
	};

	render() {
		const { isLoading, isCreating, history } = this.props;
		const {
			enid,
			name,
			client_domain,
			mgt_domain,

			errors,
			isTouched
		} = this.state;
		const editMode = enid || enid === 0;
		const buttonDisabled = isCreating || errors.name || !name.length || errors.client_domain || !client_domain.length || errors.mgt_domain || !mgt_domain.length;

		return (
			<Fragment>
				{isLoading ?
					<div className="loader-wrapper">
						<Loader />
					</div> :
					<div className="content-wrapper">
						<div className="container-fluid fields-wrapper">
							<h1 className="h3 mb-2 text-gray-800">{isLoading ? '' : (name || 'Create New Entity')}</h1>

							<div className="row">
								<div className="col-sm-12 col-md-6">
									<div className="form-group">
										<label>Name<span>*</span></label>
										<input type="text"
											className={'form-control ' + (isTouched.name && errors.name ? 'field-error' : '')}
											id="name"
											value={name}
											onChange={this.handleFieldChange} />
										{
											isTouched.name && errors.name ?
												<div className="form-control-errors-wrapper">
													{ errors.name === 'required' && <p>Name is required.</p> }
												</div> :
												null
										}
									</div>
								</div>

								<div className="col-sm-12 col-md-6">
									<div className="form-group">
										<label>Client Domain<span>*</span></label>
										<input type="text"
											className={'form-control ' + (isTouched.client_domain && errors.client_domain ? 'field-error' : '')}
											id="client_domain"
											value={client_domain}
											onChange={this.handleFieldChange} />
										{
											isTouched.client_domain && errors.client_domain ?
												<div className="form-control-errors-wrapper">
													{ errors.client_domain === 'required' && <p>Client Domain is required.</p> }
												</div> :
												null
										}
									</div>
									<div className="form-group">
										<label>Mgt Domain<span>*</span></label>
										<input type="text"
											className={'form-control ' + (isTouched.mgt_domain && errors.mgt_domain ? 'field-error' : '')}
											id="mgt_domain"
											value={mgt_domain}
											onChange={this.handleFieldChange} />
										{
											isTouched.mgt_domain && errors.mgt_domain ?
												<div className="form-control-errors-wrapper">
													{ errors.mgt_domain === 'required' && <p>Mgt Domain is required.</p> }
												</div> :
												null
										}
									</div>
								</div>
							</div>
						</div>
						<div className="container-fluid">
							<div className="row">
								<div className="col-lg-12">
									<button type="submit"
										className="btn btn-primary mb-2 float-left"
										onClick={() => history.push('/entity')}>Back</button>
									<button type="submit"
										className="btn btn-primary mb-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateEntity(e, true)}>Save</button>
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={this.handleCreateEntity}>{editMode ? 'Save and continue editing' : 'Save and duplicate new'}</button>
									{editMode ?
										<button type="submit"
											className="btn btn-primary mb-2 mr-2 float-right"
											disabled={buttonDisabled}
											onClick={e => this.handleCreateEntity(e, false, true, true)}>Save and duplicate new</button> : null
									}
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateEntity(e, false, true)}>Save and add another</button>
								</div>
							</div>
						</div>
					</div>
				}
			</Fragment>
		);
	}

}

const mapStateToProps = state => {
	return {
		entity: state.entity.entity,
		isLoading: state.entity.entityIsLoading,
		isCreating: state.entity.isCreating
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetEntityById: id => dispatch(getEntityById(id)),
		onCreateEntity: (entityData, id) => dispatch(createEntity(entityData, id))
	};
};

Entity.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	entity: PropTypes.object,
	isLoading: PropTypes.bool,
	isCreating: PropTypes.bool,
	onGetEntityById: PropTypes.func,
	onCreateEntity: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Entity);