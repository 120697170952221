import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { getAssetOptions, getAssetList, deleteAssetById } from '../../store/actions';
import utils from '../../services/utils';

import AssetRow from './AssetRow';
import Loader from '../../components/UI/Loader/Loader';
import ConfirmModal from '../../components/UI/ConfirmModal/ConfirmModal';
import './AssetList.css';

const PER_PAGE = process.env.REACT_APP_PAGE_SIZE;

class AssetList extends Component {
	state = {
		deletingAsset: {},
		search: '',
		urlParams: '',
		currentCursor: null,
		activePage: 1
	};

	componentDidMount() {
		document.title = 'Asset - ' + process.env.REACT_APP_PAGE_TITLE;
		const cursor = (this.props.location && this.props.location.state && this.props.location.state.currentCursor) || null;
		this.setState({currentCursor: cursor}, () => {
			this.props.onGetAssetOptions();
			this.props.onGetAssetList(null, (cursor || 'initial'), PER_PAGE);
		});
		document.addEventListener('keydown', this.handleKeyPress, false);
	}

	componentWillUnmount(){
		document.removeEventListener('keydown', this.handleKeyPress, false);
	}

	handleKeyPress = e => {
		const {search} = this.state;

		if(search) {
			if(e.keyCode === 27) {
				this.setState({search: ''}, () => {
					this.handleSearchBy();
				});
			} else if(e.keyCode === 13) {
				this.handleSearchBy();
			}
		}
	};

	handlePageChange = page => {
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const pageParams = utils.processPageChange(currentCursor, urlParams, page);

		this.setState(pageParams, () => {
			this.props.onGetAssetList(pageParams.urlParams, pageParams.currentCursor, PER_PAGE);
		});
	};

	handleOrderBy = sortBy => {
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;

		const { assetOptions: {asset} } = this.props;
		const fieldsObj = (asset && asset.children) || {};
		const fieldsArray = Object.keys(fieldsObj);
		const sortParams = fieldsArray.indexOf(sortBy) > -1 ? ('asset__' + (sortBy === 'pvid' ? 'pvid__name' : sortBy)) : sortBy.replace('_name', '__name');
		const orderByParams = utils.processOrderBy(currentCursor, urlParams, sortParams);

		this.setState(orderByParams, () => {
			this.props.onGetAssetList(orderByParams.urlParams, orderByParams.currentCursor, PER_PAGE);
		});
	};

	handleSearchBy = () => {
		const {search} = this.state;
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const searchByParams = utils.processSearchBy(currentCursor, urlParams, search);

		this.setState({ ...searchByParams, activePage: 1 }, () => {
			this.props.onGetAssetList(searchByParams.urlParams, searchByParams.currentCursor, PER_PAGE);
		});
	};

	handleSearchChange = event => {
		const search = event.target.value;
		this.setState({search}, () => {
			if(!search) {
				this.handleSearchBy();
			}
		});
	};

	handleDeleteAssetById = asset => {
		this.setState({deletingAsset: asset});
	};

	handleCloseModal = () => {
		this.setState({deletingAsset: {}});
	};

	handleConfirmModal = () => {
		const { deletingAsset } = this.state;
		this.props.onDeleteAssetById(deletingAsset.asset.asid).then(() => {
			this.setState({deletingAsset: {}});
		});
	};

	render() {
		const { assetOptions, assetOptions: {asset}, assetList, loading, isAssetDeleting } = this.props;
		const { deletingAsset, search, urlParams, activePage } = this.state;
		const pageCount = assetList.count || 1;
		const _assetList = (assetList && assetList.results) ? [ ...assetList.results ] : [];
		const fieldsObj = (asset && asset.children) || {};
		const fieldsArray = Object.keys(fieldsObj).sort((a) => ((a === 'hostname') ? -1 : 1));

		const fieldsRestObj = {...assetOptions};
		const { airport, pool } = fieldsRestObj;
		if(airport) {fieldsRestObj.airport_name = airport;}
		if(pool) {fieldsRestObj.pool_name = {...pool, type: 'field'};}
		delete fieldsRestObj.asset;
		const fieldsRestArray = Object.keys(fieldsRestObj);

		return (
			<div className="container-fluid">
				<div className="card shadow">
					<div className="card-header py-3">
						<h1 className="h3 text-gray-800">Assets</h1>
						<NavLink exact to="/asset/new" className="float-right">Create</NavLink>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-sm-12 col-md-6">
								<div className="input-group">
									<input type="text"
										value={search}
										className="form-control bg-light border-0 small"
										placeholder="Search for..."
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={this.handleSearchChange}/>
									<div className="input-group-append">
										<button className="btn btn-primary" type="button" onClick={this.handleSearchBy}>
											<i className="fas fa-search fa-sm"></i>
										</button>
									</div>
								</div>
								<p className="search-field-info">Search performed through all pages within all text fields.</p>
							</div>
							<div className="col-sm-12 col-md-6"></div>
						</div>

						<div className="row">
							{loading ?
								<Loader /> :
								<div className="col-sm-12 dataTables_container">
									<div className="dataTables_wrapper">
										<table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0" style={{width: '100%'}}>
											{_assetList.length ?
												<Fragment>
													<thead>
														<tr role="row">
															{fieldsArray.map(f => ((f === 'asid') ? null : <th key={f} className={`sorting${(urlParams && urlParams.indexOf(f) > -1) ? (urlParams.indexOf('-asset__' + (f === 'pvid' ? 'pvid__name' : f)) > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy(f)} style={{minWidth: '140px'}}>{fieldsObj[f].label}</th>))}
															{fieldsRestArray.map(f => ((f === 'asid' || fieldsRestArray.indexOf(f + '_name') > -1) ? null : <th key={f} className={`sorting${(urlParams && urlParams.indexOf(f.replace('_name', '__name')) > -1) ? (urlParams.indexOf('-' + f.replace('_name', '__name')) > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy(f)} style={{minWidth: '140px'}}>{fieldsRestObj[f].label}</th>))}
															<th></th>
														</tr>
													</thead>
													<tfoot>
														<tr>
															{fieldsArray.map(f => ((f === 'asid') ? null : <th key={f}>{fieldsObj[f].label}</th>))}
															{fieldsRestArray.map(f => ((f === 'asid' || fieldsRestArray.indexOf(f + '_name') > -1) ? null : <th key={f}>{fieldsRestObj[f].label}</th>))}
															<th></th>
														</tr>
													</tfoot>
												</Fragment> : null
											}
											<tbody>
												{_assetList.map(item => (
													<tr role="row" key={item.asset.asid} className="odd">
														{fieldsArray.map(f => ((f === 'asid') ? null : <AssetRow key={f} fieldId={f} asset={item.asset} fieldsOption={fieldsObj} />))}
														{fieldsRestArray.map(f => ((f === 'asid' || fieldsRestArray.indexOf(f + '_name') > -1) ? null : <AssetRow key={f} fieldId={f} asset={item} fieldsOption={fieldsRestObj} />))}
														<td>
															<div className="action-wrapper">
																<NavLink exact to={`/asset/${item.asset.asid}`} className="float-right"><i className="fas fa-edit"></i></NavLink>
																<i className="fas fa-trash" onClick={() => this.handleDeleteAssetById(item)}></i>
															</div>
														</td>
													</tr>
												))}
												{_assetList.length === 0 ?
													<tr role="row" className="odd">
														<td colSpan="20">
															<div className="empty-list">List is empty</div>
														</td>
													</tr> : null}
											</tbody>
										</table>
									</div>
								</div>
							}
						</div>

						{loading || _assetList.length === 0 ?
							null :
							<div className="row">
								<div className="col-sm-12 col-md-5"></div>
								<div className="col-sm-12 col-md-7">
									<div className="dataTables_paginate paging_simple_numbers">
										<Pagination
											innerClass="pagination float-right"
											itemClass="page-item"
											linkClass="page-link"
											activePage={activePage}
											itemsCountPerPage={PER_PAGE}
											totalItemsCount={pageCount}
											pageRangeDisplayed={5}
											onChange={this.handlePageChange}
										/>
									</div>
								</div>
							</div>
						}
					</div>
				</div>

				<ConfirmModal
					title="Delete Asset"
					content={'Are you sure, you want to delete \'' + ((deletingAsset.asset && deletingAsset.asset.hostname) || '') + '\'' }
					showModal={deletingAsset.asset && (deletingAsset.asset.asid || deletingAsset.asset.asid === 0)}
					disabled={isAssetDeleting}
					onCancel={this.handleCloseModal}
					onConfirm={this.handleConfirmModal}/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		assetOptions: state.asset.assetOptions,
		assetList: state.asset.assetList,
		loading: state.asset.loading || state.asset.assetOptionsIsLoading,
		isAssetDeleting: state.asset.isDeleting
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetAssetOptions: () => dispatch(getAssetOptions()),
		onGetAssetList: (urlParams, cursor, perPage) => dispatch(getAssetList(urlParams, cursor, perPage)),
		onDeleteAssetById: id => dispatch(deleteAssetById(id))
	};
};

AssetList.propTypes = {
	location: PropTypes.object,
	assetOptions: PropTypes.object,
	assetList: PropTypes.object,
	loading: PropTypes.bool,
	isAssetDeleting: PropTypes.bool,
	onGetAssetOptions: PropTypes.func,
	onGetAssetList: PropTypes.func,
	onDeleteAssetById: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetList);