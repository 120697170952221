import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { updateUser, logout } from './store/actions';
import utils from './services/utils';
import Navigation from './components/Navigation/Navigation';
import Toolbar from './components/Toolbar/Toolbar';
import Footer from './components/Footer/Footer';
import Login from './containers/Login/Login';
import SubnetList from './containers/SubnetList/SubnetList';
import Subnet from './containers/Subnet/Subnet';
import Entity from './containers/Entity/Entity';
import EntityList from './containers/EntityList/EntityList';
import AssetList from './containers/AssetList/AssetList';
import Asset from './containers/Asset/Asset';
import NodeList from './containers/NodeList/NodeList';
import Node from './containers/Node/Node';
import NodeGroupList from './containers/NodeGroupList/NodeGroupList';
import NodeGroup from './containers/NodeGroup/NodeGroup';
import ProviderList from './containers/ProviderList/ProviderList';
import Provider from './containers/Provider/Provider';


const App = ({ user, onUpdateUser, onLogout }) => {
	const userData = utils.getDataFromStorage('VPNDB_USER_DATA');

	if(userData && userData.username && !user.username) {
		onUpdateUser(userData);
	}

	return (
		<React.Fragment>
			{userData && userData.username ?
				<React.Fragment>
					<Navigation />
					<div id="content-wrapper" className="d-flex flex-column">
						<div id="content">
							<Toolbar onLogout={onLogout} />
							<Switch>
								<Route exact path="/subnet" component={SubnetList} />
								<Route exact path="/subnet/:id" component={Subnet} />
								<Route exact path="/entity" component={EntityList} />
								<Route exact path="/entity/:id" component={Entity} />
								<Route exact path="/asset" component={AssetList} />
								<Route exact path="/asset/:id" component={Asset} />
								<Route exact path="/node" component={NodeList} />
								<Route exact path="/node/:id" component={Node} />
								<Route exact path="/provider" component={ProviderList} />
								<Route exact path="/provider/:id" component={Provider} />
								<Route exact path="/nodegroup" component={NodeGroupList} />
								<Route exact path="/nodegroup/:id" component={NodeGroup} />
								<Redirect to="/subnet" />
							</Switch>
						</div>
						<Footer />
					</div>
				</React.Fragment> :
				<Switch>
					<Route exact path="/login" component={Login} />
					<Redirect to="/login" />
				</Switch>
			}
			<ToastContainer />
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		user: state.auth.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUpdateUser: user => dispatch(updateUser(user)),
		onLogout: () => dispatch(logout())
	};
};

App.propTypes = {
	user: PropTypes.object,
	onUpdateUser: PropTypes.func,
	onLogout: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
