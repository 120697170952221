import modules from './modules';

import {
	GET_ENTITY_LIST_START,
	GET_ENTITY_LIST_SUCCESS,
	GET_ENTITY_LIST_FAIL,

	GET_ENTITY_START,
	GET_ENTITY_SUCCESS,
	GET_ENTITY_FAIL,

	CREATE_ENTITY_START,
	CREATE_ENTITY_SUCCESS,
	CREATE_ENTITY_FAIL,

	DELETE_ENTITY_START,
	DELETE_ENTITY_SUCCESS,
	DELETE_ENTITY_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

const { API, utils, toast } = modules;

/* entity list start */
export const getEntityListStart = () => {
	return {
		type: GET_ENTITY_LIST_START
	};
};

export const getEntityListSuccess = entityList => {
	return {
		type: GET_ENTITY_LIST_SUCCESS,
		entityList
	};
};

export const getEntityListFail = () => {
	return {
		type: GET_ENTITY_LIST_FAIL
	};
};

export const getEntityList = (ordering, cursor, perPage) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getEntityListStart());
		return API.getEntityList(userData.token, ordering, cursor, perPage)
			.then(response => {
				const entityList = (response && response.data) || {};
				dispatch(getEntityListSuccess(entityList));
				return entityList;
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				dispatch(getEntityListFail());
				return {};
			});

	};
};
/* entity list end */

/* entity start */
export const getEntityStart = () => {
	return {
		type: GET_ENTITY_START
	};
};

export const getEntitySuccess = entity => {
	return {
		type: GET_ENTITY_SUCCESS,
		entity
	};
};

export const getEntityFail = () => {
	return {
		type: GET_ENTITY_FAIL
	};
};

export const getEntityById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getEntityStart());
		return API.getEntityById(userData.token, id)
			.then(response => {
				dispatch(getEntitySuccess(response.data));
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				dispatch(getEntityFail());
			});

	};
};
/* entity end */

/* create/edit entity start */
export const createEntityStart = () => {
	return {
		type: CREATE_ENTITY_START
	};
};

export const createEntitySuccess = entity => {
	return {
		type: CREATE_ENTITY_SUCCESS,
		entity
	};
};

export const createEntityFail = () => {
	return {
		type: CREATE_ENTITY_FAIL
	};
};

export const createEntity = (entityData, id) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(createEntityStart());
		return API.createEntity(userData.token, entityData, id)
			.then(response => {
				dispatch(createEntitySuccess(response.data));
				toast.success(`Entity ${(id == null) ? 'created' : 'edited'} successfully`, {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				let message = 'Something went wrong';
				const resp = (error && error.response) || '';
				if(resp.data && typeof resp.data !== 'string') {
					const errors = resp.data;
					for(var o in errors) {
						message = (errors[o] && errors[o][0]) || 'Something went wrong';
					}
				}
				utils.handleError(error, dispatch, UPDATE_USER_DATA, message);
				dispatch(createEntityFail());
				return (error && error.response) || {};
			});

	};
};
/* create/edit entity end */

/* delete entity start */
export const deleteEntityStart = () => {
	return {
		type: DELETE_ENTITY_START
	};
};

export const deleteEntitySuccess = entityList => {
	return {
		type: DELETE_ENTITY_SUCCESS,
		entityList
	};
};

export const deleteEntityFail = () => {
	return {
		type: DELETE_ENTITY_FAIL
	};
};

export const deleteEntityById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(deleteEntityStart());
		return API.deleteEntityById(userData.token, id)
			.then(() => {
				let _entityList = getState().entity.entityList || {};
				_entityList.results = (_entityList.results || []).filter(e => e.enid !== id);
				dispatch(deleteEntitySuccess(_entityList));
				toast.success('Entity deleted successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return id;
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA, 'Something went wrong deleting entity');
				dispatch(deleteEntityFail());
			});

	};
};
/* delete entity end */