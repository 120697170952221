import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSubnetById, createSubnet } from '../../store/actions';
import ip from 'ip';
import moment from 'moment';

import Loader from '../../components/UI/Loader/Loader';
import './Subnet.css';

const validation = {
	prefix: ['required'],
	cidr: ['required', 'number', 'validation'],
	aton_st: ['number'],
	aton_en: ['number']
};

const INITIALSTATE = {
	snid: null,
	prefix: '',
	cidr: '',
	aton_st: '',
	aton_short_st: '',
	aton_en: '',
	aton_short_en: '',
	_addresses_length: 0,
	modified: 0,
	created: 0,

	errors: {},
	isTouched: {}
};

class Subnet extends Component {
	state = {...INITIALSTATE};

	componentDidMount() {
		document.title = 'Subnet - ' + process.env.REACT_APP_PAGE_TITLE;
		const { location: { pathname } } = this.props;
		const snid = pathname.split('/subnet/')[1];
		if(snid !== 'new') {
			this.props.onGetSubnetById(snid);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { location: { pathname } } = this.props;
		const snid = pathname.split('/subnet/')[1];
		if(!nextProps.isCreating && nextProps.subnet && nextProps.subnet.snid && (snid !== 'new')) {
			const data = nextProps.subnet;
			document.title = (data && (data.prefix + ' - ' + process.env.REACT_APP_PAGE_TITLE)) || ('Subnet - ' + process.env.REACT_APP_PAGE_TITLE);
			let _cidr = (data && data.cidr) || '',
				_prefix = (data && data.prefix) || '',
				_aton_st = (data && data.aton_st) || '',
				_aton_short_st = '',
				_aton_en = (data && data.aton_en) || '',
				_aton_short_en = '',
				_addresses_length = 0;
			if(_cidr.length) {
				if(ip.isV4Format(_cidr.split('/')[0]) && (_cidr.split('/')).length === 2) {
					_aton_st = ip.toLong(ip.cidrSubnet(_cidr).networkAddress);
					_aton_short_st = ip.cidrSubnet(_cidr).networkAddress;
					_aton_en = ip.toLong(ip.cidrSubnet(_cidr).broadcastAddress);
					_aton_short_en = ip.cidrSubnet(_cidr).broadcastAddress;
					_addresses_length = ip.cidrSubnet(_cidr).length;
				}
			}
			this.setState({
				snid: (data && data.snid) || null,
				prefix: _prefix,
				cidr: _cidr,
				aton_st: _aton_st,
				aton_short_st: _aton_short_st,
				aton_en: _aton_en,
				aton_short_en: _aton_short_en,
				addresses_length: _addresses_length,
				modified: (data && data.modified) || 0,
				created: (data && data.created) || 0
			});
		}
	}

	handleFieldChange = event => {
		const { isTouched, errors, prefix, aton_st, aton_short_st, aton_en, aton_short_en, addresses_length } = this.state;
		const id = event.target.id;
		const value = event.target.value;
		let _prefix = prefix, _aton_st = aton_st, _aton_short_st = aton_short_st, _aton_en = aton_en, _aton_short_en = aton_short_en, _addresses_length = addresses_length;
		const _errors = { ...errors };
		const _isTouched = {
			...isTouched,
			[id]: true
		};

		if(validation[id].indexOf('required') > -1 && (value + '').length === 0) {
			_errors[id] = 'required';
		} else if(validation[id].indexOf('number') > -1 && value.length && !(parseInt(value) > -1)) {
			_errors[id] = 'number';
		} else if(id === 'cidr' && validation[id].indexOf('validation') > -1 && ip.isV4Format(value.split('/')[0]) && (value.split('/')).length !== 2) {
			_errors[id] = 'validation';
		} else {
			delete _errors[id];
		}
		if(id === 'cidr' && value.length) {
			if(ip.isV4Format(value.split('/')[0]) && (value.split('/')).length === 2) {
				_aton_st = ip.toLong(ip.cidrSubnet(value).networkAddress);
				_aton_short_st = ip.cidrSubnet(value).networkAddress;
				_aton_en = ip.toLong(ip.cidrSubnet(value).broadcastAddress);
				_aton_short_en = ip.cidrSubnet(value).broadcastAddress;
				_addresses_length = ip.cidrSubnet(value).length;
				_prefix = value.split('/')[1];
			}
		}

		this.setState({
			[id]: value,
			prefix: _prefix,
			aton_st: _aton_st,
			aton_short_st: _aton_short_st,
			aton_en: _aton_en,
			aton_short_en: _aton_short_en,
			addresses_length: _addresses_length,
			isTouched: _isTouched,
			errors: _errors
		});
	};

	handleCreateSubnet = (e, redirect, openNewBlank, duplicate) => {
		const { prefix, cidr, aton_st, aton_en } = this.state;
		const { history, location: { pathname } } = this.props;
		const snid = pathname.split('/subnet/')[1];
		if(prefix && cidr) {
			const dataToSend = {
				prefix,
				cidr,
			};
			dataToSend.aton_st = (aton_st.length === 0) ? null : aton_st;
			dataToSend.aton_en = (aton_en.length === 0) ? null : aton_en;
			this.props.onCreateSubnet(dataToSend, (snid === 'new' ? null : snid)).then(response => {
				if(response.status && response.status !== 200 && response.status !== 201) {
					return;
				}
				if(redirect) {
					history.push('/subnet');
				}
				if(openNewBlank) {
					history.push('/subnet/new');
					this.setState(duplicate ? {snid: null} : {...INITIALSTATE});
				}
			});
		}
	};

	render() {
		const { isLoading, isCreating, history } = this.props;
		const {
			snid,
			prefix,
			cidr,
			aton_st,
			aton_short_st,
			aton_en,
			aton_short_en,
			addresses_length,
			modified,
			created,

			errors,
			isTouched
		} = this.state;
		const editMode = snid || snid === 0;
		const buttonDisabled = isCreating || errors.prefix || !prefix.length || errors.cidr || !(cidr + '').length || errors.aton_st || errors.aton_en;

		return (
			<Fragment>
				{isLoading ?
					<div className="loader-wrapper">
						<Loader />
					</div> :
					<div className="content-wrapper">
						<div className="container-fluid fields-wrapper">
							<h1 className="h3 mb-2 text-gray-800">{isLoading ? '' : snid ? cidr : 'Create New Subnet'}</h1>

							<div className="row">
								<div className="col-sm-12 col-md-6">
									{editMode && created ? <p className="mb-0 text-gray-800">Created: {moment(1000*created).format('YYYY-MM-DD')}</p> : null}
									{editMode && modified ? <p className="mb-4 text-gray-800">Modified: {moment(1000*modified).format('YYYY-MM-DD')}</p> : null}
								</div>
								<div className="col-sm-12 col-md-6">
									{addresses_length ? <p className="mb-0 text-gray-800">Addresses Length: {addresses_length}</p> : null}
								</div>
							</div>


							<div className="row">
								<div className="col-sm-12 col-md-6">
									<div className="form-group">
										<label>CIDR<span>*</span></label><span className="hint">e.g. 192.168.1.1/24</span>
										<input type="text"
											className={'form-control ' + (isTouched.cidr && errors.cidr ? 'field-error' : '')}
											id="cidr"
											value={cidr}
											onChange={this.handleFieldChange} />
										{
											isTouched.cidr && errors.cidr ?
												<div className="form-control-errors-wrapper">
													{ errors.cidr === 'required' && <p>Cidr is required.</p> }
													{ errors.cidr === 'number' && <p>A valid integer is required.</p> }
													{ errors.cidr === 'validation' && <p>Invalid CIDR Subnet.</p> }
												</div> :
												null
										}
									</div>
									<div className="form-group">
										<label>Prefix</label>
										<input type="text"
											className={'form-control ' + (isTouched.prefix && errors.prefix ? 'field-error' : '')}
											id="prefix"
											value={prefix}
											disabled
											onChange={this.handleFieldChange} />
										{
											isTouched.prefix && errors.prefix ?
												<div className="form-control-errors-wrapper">
													{ errors.prefix === 'required' && <p>Prefix is required.</p> }
												</div> :
												null
										}
									</div>
								</div>

								<div className="col-sm-12 col-md-6">
									<div className="form-group">
										<label>Aton Start</label><span className="hint">{aton_short_st}</span>
										<input type="text"
											className={'form-control ' + (isTouched.aton_st && errors.aton_st ? 'field-error' : '')}
											id="aton_st"
											value={aton_st}
											disabled
											onChange={this.handleFieldChange} />
										{
											isTouched.aton_st && errors.aton_st ?
												<div className="form-control-errors-wrapper">
													{ errors.aton_st === 'number' && <p>A valid integer is required.</p> }
												</div> :
												null
										}
									</div>
									<div className="form-group">
										<label>Aton End</label><span className="hint">{aton_short_en}</span>
										<input type="text"
											className={'form-control ' + (isTouched.aton_en && errors.aton_en ? 'field-error' : '')}
											id="aton_en"
											value={aton_en}
											disabled
											onChange={this.handleFieldChange} />
										{
											isTouched.aton_en && errors.aton_en ?
												<div className="form-control-errors-wrapper">
													{ errors.aton_en === 'number' && <p>A valid integer is required.</p> }
												</div> :
												null
										}
									</div>
								</div>
							</div>
						</div>

						<div className="container-fluid">
							<div className="row">
								<div className="col-lg-12">
									<button type="submit"
										className="btn btn-primary mb-2 float-left"
										onClick={() => history.push('/subnet')}>Back</button>
									<button type="submit"
										className="btn btn-primary mb-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateSubnet(e, true)}>Save</button>
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={this.handleCreateSubnet}>{editMode ? 'Save and continue editing' : 'Save and duplicate new'}</button>
									{editMode ?
										<button type="submit"
											className="btn btn-primary mb-2 mr-2 float-right"
											disabled={buttonDisabled}
											onClick={e => this.handleCreateSubnet(e, false, true, true)}>Save and duplicate new</button> : null
									}
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateSubnet(e, false, true)}>Save and add another</button>
								</div>
							</div>
						</div>
					</div>
				}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		subnet: state.subnet.subnet,
		isLoading: state.subnet.subnetIsLoading,
		isCreating: state.subnet.isCreating
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetSubnetById: id => dispatch(getSubnetById(id)),
		onCreateSubnet: (subnetData, id) => dispatch(createSubnet(subnetData, id))
	};
};

Subnet.propTypes = {
	subnet: PropTypes.object,
	location: PropTypes.object,
	history: PropTypes.object,
	isLoading: PropTypes.bool,
	isCreating: PropTypes.bool,
	onGetSubnetById: PropTypes.func,
	onCreateSubnet: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Subnet);