import {
	GET_ASSET_LIST_START,
	GET_ASSET_LIST_SUCCESS,
	GET_ASSET_LIST_FAIL,

	GET_ASSET_START,
	GET_ASSET_SUCCESS,
	GET_ASSET_FAIL,

	CREATE_ASSET_START,
	CREATE_ASSET_SUCCESS,
	CREATE_ASSET_FAIL,

	DELETE_ASSET_START,
	DELETE_ASSET_SUCCESS,
	DELETE_ASSET_FAIL,

	GET_ASSET_OPTIONS_START,
	GET_ASSET_OPTIONS_SUCCESS,
	GET_ASSET_OPTIONS_FAIL
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	assetList: {},
	asset: {},
	assetOptions: {},
	loading: false,
	assetIsLoading: false,
	isCreating: false,
	isDeleting: false,
	assetOptionsIsLoading: false
};

/* asset list start */
const getAssetListStart = (state) => {
	return utils.updateObject(state, {
		loading: true
	});
};

const getAssetListSuccess = (state, action) => {
	return utils.updateObject(state, {
		assetList: action.assetList,
		loading: false
	});
};

const getAssetListFail = (state) => {
	return utils.updateObject(state, {
		assetList: {},
		loading: false
	});
};
/* asset list end */

/* asset start */
const getAssetStart = (state) => {
	return utils.updateObject(state, {
		asset: {},
		assetIsLoading: true
	});
};

const getAssetSuccess = (state, action) => {
	return utils.updateObject(state, {
		asset: action.asset,
		assetIsLoading: false
	});
};

const getAssetFail = (state) => {
	return utils.updateObject(state, {
		asset: {},
		assetIsLoading: false
	});
};
/* asset end */

/* asset create/edit start */
const createAssetStart = (state) => {
	return utils.updateObject(state, {
		isCreating: true
	});
};

const createAssetSuccess = (state, action) => {
	return utils.updateObject(state, {
		asset: action.asset,
		isCreating: false
	});
};

const createAssetFail = (state) => {
	return utils.updateObject(state, {
		isCreating: false
	});
};
/* asset create/edit end */

/* asset delete start */
const deleteAssetStart = (state) => {
	return utils.updateObject(state, {
		isDeleting: true
	});
};

const deleteAssetSuccess = (state, action) => {
	return utils.updateObject(state, {
		assetList: action.assetList,
		isDeleting: false
	});
};

const deleteAssetFail = (state) => {
	return utils.updateObject(state, {
		isDeleting: false
	});
};
/* asset delete end */

/* asset options start */
const getAssetOptionsStart = (state) => {
	return utils.updateObject(state, {
		assetOptions: {},
		assetOptionsIsLoading: true
	});
};

const getAssetOptionsSuccess = (state, action) => {
	return utils.updateObject(state, {
		assetOptions: action.assetOptions,
		assetOptionsIsLoading: false
	});
};

const getAssetOptionsFail = (state) => {
	return utils.updateObject(state, {
		assetOptions: {},
		assetOptionsIsLoading: false
	});
};
/* asset options end */


const assetReducer = (state = initialState, action = {}) => {
	switch (action.type) {
	case GET_ASSET_LIST_START: return getAssetListStart(state, action);
	case GET_ASSET_LIST_SUCCESS: return getAssetListSuccess(state, action);
	case GET_ASSET_LIST_FAIL: return getAssetListFail(state, action);

	case GET_ASSET_START: return getAssetStart(state, action);
	case GET_ASSET_SUCCESS: return getAssetSuccess(state, action);
	case GET_ASSET_FAIL: return getAssetFail(state, action);

	case CREATE_ASSET_START: return createAssetStart(state, action);
	case CREATE_ASSET_SUCCESS: return createAssetSuccess(state, action);
	case CREATE_ASSET_FAIL: return createAssetFail(state, action);

	case DELETE_ASSET_START: return deleteAssetStart(state, action);
	case DELETE_ASSET_SUCCESS: return deleteAssetSuccess(state, action);
	case DELETE_ASSET_FAIL: return deleteAssetFail(state, action);

	case GET_ASSET_OPTIONS_START: return getAssetOptionsStart(state, action);
	case GET_ASSET_OPTIONS_SUCCESS: return getAssetOptionsSuccess(state, action);
	case GET_ASSET_OPTIONS_FAIL: return getAssetOptionsFail(state, action);

	default:
		return state;
	}
};

export default assetReducer;