import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import './ErrorBoundary.css';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {error: null, errorInfo: null, eventId: null};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
		Sentry.withScope(scope => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({eventId});
		});
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<div className="error-boundary-wrapper">
					<div className="">
						<h2>Something went wrong</h2>
						<p>We will fix it soon</p>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.object
};

export default ErrorBoundary;