import {
	GET_SUBNET_LIST_START,
	GET_SUBNET_LIST_SUCCESS,
	GET_SUBNET_LIST_FAIL,

	GET_SUBNET_START,
	GET_SUBNET_SUCCESS,
	GET_SUBNET_FAIL,

	CREATE_SUBNET_START,
	CREATE_SUBNET_SUCCESS,
	CREATE_SUBNET_FAIL,

	DELETE_SUBNET_START,
	DELETE_SUBNET_SUCCESS,
	DELETE_SUBNET_FAIL
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	subnetList: {},
	subnet: {},
	loading: false,
	subnetIsLoading: false,
	isCreating: false,
	isDeleting: false
};

/* subnet list start */
const getSubnetListStart = (state) => {
	return utils.updateObject(state, {
		loading: true
	});
};

const getSubnetListSuccess = (state, action) => {
	return utils.updateObject(state, {
		subnetList: action.subnetList,
		loading: false
	});
};

const getSubnetListFail = (state) => {
	return utils.updateObject(state, {
		subnetList: {},
		loading: false
	});
};
/* subnet list end */

/* subnet start */
const getSubnetStart = (state) => {
	return utils.updateObject(state, {
		subnetIsLoading: true
	});
};

const getSubnetSuccess = (state, action) => {
	return utils.updateObject(state, {
		subnet: action.subnet,
		subnetIsLoading: false
	});
};

const getSubnetFail = (state) => {
	return utils.updateObject(state, {
		subnet: {},
		subnetIsLoading: false
	});
};
/* subnet end */

/* subnet create/edit start */
const createSubnetStart = (state) => {
	return utils.updateObject(state, {
		isCreating: true
	});
};

const createSubnetSuccess = (state, action) => {
	return utils.updateObject(state, {
		subnet: action.subnet,
		isCreating: false
	});
};

const createSubnetFail = (state) => {
	return utils.updateObject(state, {
		isCreating: false
	});
};
/* subnet create/edit end */

/* subnet delete start */
const deleteSubnetStart = (state) => {
	return utils.updateObject(state, {
		isDeleting: true
	});
};

const deleteSubnetSuccess = (state, action) => {
	return utils.updateObject(state, {
		subnetList: action.subnetList,
		isDeleting: false
	});
};

const deleteSubnetFail = (state) => {
	return utils.updateObject(state, {
		isDeleting: false
	});
};
/* subnet delete end */


const subnetReducer = (state = initialState, action = {}) => {
	switch (action.type) {
	case GET_SUBNET_LIST_START: return getSubnetListStart(state, action);
	case GET_SUBNET_LIST_SUCCESS: return getSubnetListSuccess(state, action);
	case GET_SUBNET_LIST_FAIL: return getSubnetListFail(state, action);

	case GET_SUBNET_START: return getSubnetStart(state, action);
	case GET_SUBNET_SUCCESS: return getSubnetSuccess(state, action);
	case GET_SUBNET_FAIL: return getSubnetFail(state, action);

	case CREATE_SUBNET_START: return createSubnetStart(state, action);
	case CREATE_SUBNET_SUCCESS: return createSubnetSuccess(state, action);
	case CREATE_SUBNET_FAIL: return createSubnetFail(state, action);

	case DELETE_SUBNET_START: return deleteSubnetStart(state, action);
	case DELETE_SUBNET_SUCCESS: return deleteSubnetSuccess(state, action);
	case DELETE_SUBNET_FAIL: return deleteSubnetFail(state, action);

	default:
		return state;
	}
};

export default subnetReducer;