import {
	GET_ENTITY_LIST_START,
	GET_ENTITY_LIST_SUCCESS,
	GET_ENTITY_LIST_FAIL,

	GET_ENTITY_START,
	GET_ENTITY_SUCCESS,
	GET_ENTITY_FAIL,

	CREATE_ENTITY_START,
	CREATE_ENTITY_SUCCESS,
	CREATE_ENTITY_FAIL,

	DELETE_ENTITY_START,
	DELETE_ENTITY_SUCCESS,
	DELETE_ENTITY_FAIL
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	entityList: {},
	entity: {},
	loading: false,
	entityIsLoading: false,
	isCreating: false,
	isDeleting: false
};

/* entity list start */
const getEntityListStart = (state) => {
	return utils.updateObject(state, {
		loading: true
	});
};

const getEntityListSuccess = (state, action) => {
	return utils.updateObject(state, {
		entityList: action.entityList,
		loading: false
	});
};

const getEntityListFail = (state) => {
	return utils.updateObject(state, {
		entityList: {},
		loading: false
	});
};
/* entity list end */

/* entity start */
const getEntityStart = (state) => {
	return utils.updateObject(state, {
		entityIsLoading: true
	});
};

const getEntitySuccess = (state, action) => {
	return utils.updateObject(state, {
		entity: action.entity,
		entityIsLoading: false
	});
};

const getEntityFail = (state) => {
	return utils.updateObject(state, {
		entity: {},
		entityIsLoading: false
	});
};
/* entity end */

/* entity create/edit start */
const createEntityStart = (state) => {
	return utils.updateObject(state, {
		isCreating: true
	});
};

const createEntitySuccess = (state, action) => {
	return utils.updateObject(state, {
		entity: action.entity,
		isCreating: false
	});
};

const createEntityFail = (state) => {
	return utils.updateObject(state, {
		isCreating: false
	});
};
/* entity create/edit end */

/* entity delete start */
const deleteEntityStart = (state) => {
	return utils.updateObject(state, {
		isDeleting: true
	});
};

const deleteEntitySuccess = (state, action) => {
	return utils.updateObject(state, {
		entityList: action.entityList,
		isDeleting: false
	});
};

const deleteEntityFail = (state) => {
	return utils.updateObject(state, {
		isDeleting: false
	});
};
/* entity delete end */


const entityReducer = (state = initialState, action = {}) => {
	switch (action.type) {
	case GET_ENTITY_LIST_START: return getEntityListStart(state, action);
	case GET_ENTITY_LIST_SUCCESS: return getEntityListSuccess(state, action);
	case GET_ENTITY_LIST_FAIL: return getEntityListFail(state, action);

	case GET_ENTITY_START: return getEntityStart(state, action);
	case GET_ENTITY_SUCCESS: return getEntitySuccess(state, action);
	case GET_ENTITY_FAIL: return getEntityFail(state, action);

	case CREATE_ENTITY_START: return createEntityStart(state, action);
	case CREATE_ENTITY_SUCCESS: return createEntitySuccess(state, action);
	case CREATE_ENTITY_FAIL: return createEntityFail(state, action);

	case DELETE_ENTITY_START: return deleteEntityStart(state, action);
	case DELETE_ENTITY_SUCCESS: return deleteEntitySuccess(state, action);
	case DELETE_ENTITY_FAIL: return deleteEntityFail(state, action);

	default:
		return state;
	}
};

export default entityReducer;