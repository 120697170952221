import React from 'react';
import PropTypes from 'prop-types';
import './CustomCheckbox.css';

const CustomCheckbox = ({
	id,
	label,
	value,
	type,
	disabled,
	required,
	isTouched,
	errors,
	onChange
}) => (
	<div>
		<div className="custom-control custom-checkbox">
			<input type="checkbox"
				className="custom-control-input"
				id={id}
				disabled={disabled}
				checked={value}
				onChange={e => onChange(e, type)}
			/>
			<label className="custom-control-label" htmlFor={id}>
				{label}
				{required ? <span>*</span> : null}
			</label>
		</div>
		{
			isTouched[id] && errors[id] ?
				<div className="form-control-errors-wrapper">
					{ errors[id] === 'required' && <p>{label} is required.</p> }
				</div> :
				null
		}
	</div>
);

CustomCheckbox.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.bool,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	isTouched: PropTypes.object,
	errors: PropTypes.object,
	onChange: PropTypes.func
};

export default CustomCheckbox;