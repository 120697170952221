import React from 'react';
import PropTypes from 'prop-types';
import './ConfirmModal.css';


const ConfirmModal = ({title, content, showModal, disabled, onCancel, onConfirm}) => {
	return (
		<div className="modal" style={{display: (showModal ? 'flex' : 'none')}}>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h4 className="modal-title" id="modalLabel">{title || ''}</h4>
						<button type="button" className="close" onClick={onCancel} disabled={disabled}>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{content || ''}
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={onCancel} disabled={disabled}>Cancel</button>
						<button type="button" className="btn btn-danger" onClick={onConfirm} disabled={disabled}>Confirm</button>
					</div>
				</div>
			</div>
		</div>
	);
};

ConfirmModal.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
	showModal: PropTypes.node,
	disabled: PropTypes.bool,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func
};

export default ConfirmModal;