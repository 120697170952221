import modules from './modules';

import {
	LOGIN_START,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	UPDATE_USER_DATA,
	LOGOUT_START,
	LOGOUT_SUCCESS,
	LOGOUT_FAIL
} from './actionTypes';

const { API, utils, Sentry, toast } = modules;

/* auth start */
const loginStart = () => {
	return {
		type: LOGIN_START
	};
};

const loginSuccess = user => {
	return {
		type: LOGIN_SUCCESS,
		user
	};
};

const loginFail = () => {
	return {
		type: LOGIN_FAIL
	};
};

const updateUserData = user => {
	return {
		type: UPDATE_USER_DATA,
		user
	};
};

const logoutStart = () => {
	return {
		type: LOGOUT_START
	};
};

const logoutSuccess = user => {
	return {
		type: LOGOUT_SUCCESS,
		user
	};
};

const logoutFail = () => {
	return {
		type: LOGOUT_FAIL
	};
};

export const login = authData => {
	return (dispatch) => {
		dispatch(loginStart());

		API.login(authData)
			.then((response) => {
				if (response.status === 200 && response.data && response.data.token && response.data.token.length) {
					let userDetails = {
						token: response.data.token,
						user_id: response.data.user_id,
						username: authData.username
					};
					utils.setDataToStorage('VPNDB_USER_DATA', userDetails);
					dispatch(loginSuccess(userDetails));
					toast.success('You have successfully signed in', {
						position: toast.POSITION.TOP_RIGHT
					});
				} else {
					dispatch(loginFail({}));
					toast.error('Something went wrong, please try again later.', {
						position: toast.POSITION.TOP_RIGHT
					});
				}
			})
			.catch(error => {
				dispatch(loginFail({}));

				let message = 'Something went wrong';
				const resp = (error && error.response) || '';
				if(resp.data && typeof resp.data !== 'string') {
					const errors = resp.data;
					const errorsArray = [];
					for(var o in errors) {
						message = (errors[o] && errors[o][0]) || 'You\'ve entered an incorrect username or password';
						errorsArray.push(message);
					}
				}
				if(error.response && error.response.status >= 500) {
					message = 'Not available to public users';
				}
				toast.error(message, {
					position: toast.POSITION.TOP_RIGHT
				});
				Sentry.captureException(error);
				console.log('ERROR', error);
			});
	};
};

export const updateUser = user => {
	return (dispatch) => {
		dispatch(updateUserData(user));
	};
};

export const logout = () => {
	const userDetails = utils.getDataFromStorage('VPNDB_USER_DATA');
	const token = userDetails && userDetails.token;

	return dispatch => {
		dispatch(logoutStart());
		API.logout(token)
			.then((response) => {
				utils.deleteDataFromStorage('VPNDB_USER_DATA');
				dispatch(logoutSuccess({}));
				if (response.status === 200) {
					toast.success('You have successfully logged out', {
						position: toast.POSITION.TOP_RIGHT
					});
				}
			})
			.catch(error => {
				utils.deleteDataFromStorage('VPNDB_USER_DATA');
				dispatch(logoutFail({}));

				let message = 'Something went wrong';
				if(error.response && error.response.status >= 500) {
					message = 'Not available to public users';
				}
				toast.error(message, {
					position: toast.POSITION.TOP_RIGHT
				});
				Sentry.captureException(error);
				console.log(error);
			});
	};
};
/* auth end */