import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { getNodeOptions, getNodeList, deleteNodeById } from '../../store/actions';
import utils from '../../services/utils';

import NodeRow from './NodeRow';
import Loader from '../../components/UI/Loader/Loader';
import ConfirmModal from '../../components/UI/ConfirmModal/ConfirmModal';
import './NodeList.css';

const PER_PAGE = process.env.REACT_APP_PAGE_SIZE;


class NodeList extends Component {
	state = {
		deletingNode: {},
		search: '',
		urlParams: '',
		currentCursor: null,
		activePage: 1
	};

	componentDidMount() {
		document.title = 'Node - ' + process.env.REACT_APP_PAGE_TITLE;
		const cursor = (this.props.location && this.props.location.state && this.props.location.state.currentCursor) || null;
		this.setState({currentCursor: cursor}, () => {
			this.props.onGetNodeOptions();
			this.props.onGetNodeList(null, (cursor || 'initial'), PER_PAGE);
		});
		document.addEventListener('keydown', this.handleKeyPress, false);
	}

	componentWillUnmount(){
		document.removeEventListener('keydown', this.handleKeyPress, false);
	}

	handleKeyPress = e => {
		const {search} = this.state;

		if(search) {
			if(e.keyCode === 27) {
				this.setState({search: ''}, () => {
					this.handleSearchBy();
				});
			} else if(e.keyCode === 13) {
				this.handleSearchBy();
			}
		}
	};

	handlePageChange = page => {
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const pageParams = utils.processPageChange(currentCursor, urlParams, page);

		this.setState(pageParams, () => {
			this.props.onGetNodeList(pageParams.urlParams, pageParams.currentCursor, PER_PAGE);
		});
	};

	handleOrderBy = sortBy => {
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const orderByParams = utils.processOrderBy(currentCursor, urlParams, sortBy);

		this.setState(orderByParams, () => {
			this.props.onGetNodeList(orderByParams.urlParams, orderByParams.currentCursor, PER_PAGE);
		});
	};

	handleSearchBy = () => {
		const {search} = this.state;
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const searchByParams = utils.processSearchBy(currentCursor, urlParams, search);

		this.setState({ ...searchByParams, activePage: 1 }, () => {
			this.props.onGetNodeList(searchByParams.urlParams, searchByParams.currentCursor, PER_PAGE);
		});
	};

	handleSearchChange = event => {
		const search = event.target.value;
		this.setState({search}, () => {
			if(!search) {
				this.handleSearchBy();
			}
		});
	};

	handleDeleteNodeById = node => {
		this.setState({deletingNode: node});
	};

	handleCloseModal = () => {
		this.setState({deletingNode: {}});
	};

	handleConfirmModal = () => {
		const { deletingNode } = this.state;
		this.props.onDeleteNodeById(deletingNode.ndid).then(() => {
			this.setState({deletingNode: {}});
		});
	};

	render() {
		const { nodeOptions, nodeList, loading, isNodeDeleting } = this.props;
		const { deletingNode, search, urlParams, activePage } = this.state;
		const pageCount = nodeList.count || 1;
		const _nodeList = (nodeList && nodeList.results) ? [ ...nodeList.results ] : [];
		const fieldsObj = {...nodeOptions};
		const fieldsArray = Object.keys(fieldsObj).sort((a) => ((a === 'hostname') ? -1 : 1));

		return (
			<div className="container-fluid">
				<div className="card shadow">
					<div className="card-header py-3">
						<h1 className="h3 text-gray-800">Node</h1>
						<NavLink exact to="/node/new" className="float-right">Create</NavLink>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-sm-12 col-md-6">
								<div className="input-group">
									<input type="text"
										value={search}
										className="form-control bg-light border-0 small"
										placeholder="Search for..."
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={this.handleSearchChange}/>
									<div className="input-group-append">
										<button className="btn btn-primary" type="button" onClick={this.handleSearchBy}>
											<i className="fas fa-search fa-sm"></i>
										</button>
									</div>
								</div>
								<p className="search-field-info">Search performed through all pages within all text fields.</p>
							</div>
							<div className="col-sm-12 col-md-6"></div>
						</div>

						<div className="row">
							{loading ?
								<Loader /> :
								<div className="col-sm-12 dataTables_container">
									<div className="dataTables_wrapper">
										<table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0" style={{width: '100%'}}>
											{_nodeList.length ?
												<Fragment>
													<thead>
														<tr role="row">
															{fieldsArray.map(f => ((f === 'ndid' || f === 'enid' || f === 'asid' || f === 'snid') ? null : <th key={f} className={`sorting${(urlParams && urlParams.indexOf(f) > -1) ? (urlParams.indexOf('-' + f) > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy(f)} style={{minWidth: '140px'}}>{fieldsObj[f].label}</th>))}
															<th></th>
														</tr>
													</thead>
													<tfoot>
														<tr>
															{fieldsArray.map(f => ((f === 'ndid' || f === 'enid' || f === 'asid' || f === 'snid') ? null : <th key={f}>{fieldsObj[f].label}</th>))}
															<th></th>
														</tr>
													</tfoot>
												</Fragment> : null
											}
											<tbody>
												{_nodeList.map(item => (
													<tr role="row" key={item.ndid} className="odd">
														{fieldsArray.map(f => ((f === 'ndid' || f === 'enid' || f === 'asid' || f === 'snid') ? null : <NodeRow key={f} fieldId={f} node={item} fieldsOption={fieldsObj} />))}
														<td>
															<div className="action-wrapper">
																<NavLink exact to={`/node/${item.ndid}`} className="float-right"><i className="fas fa-edit"></i></NavLink>
																<i className="fas fa-trash" onClick={() => this.handleDeleteNodeById(item)}></i>
															</div>
														</td>
													</tr>
												))}
												{_nodeList.length === 0 ?
													<tr role="row" className="odd">
														<td colSpan="15">
															<div className="empty-list">List is empty</div>
														</td>
													</tr> : null}
											</tbody>
										</table>
									</div>
								</div>
							}
						</div>

						{loading || _nodeList.length === 0 ?
							null :
							<div className="row">
								<div className="col-sm-12 col-md-5"></div>
								<div className="col-sm-12 col-md-7">
									<div className="dataTables_paginate paging_simple_numbers">
										<Pagination
											innerClass="pagination float-right"
											itemClass="page-item"
											linkClass="page-link"
											activePage={activePage}
											itemsCountPerPage={PER_PAGE}
											totalItemsCount={pageCount}
											pageRangeDisplayed={5}
											onChange={this.handlePageChange}
										/>
									</div>
								</div>
							</div>
						}
					</div>
				</div>

				<ConfirmModal
					title="Delete Node"
					content={'Are you sure, you want to delete \'' + deletingNode.hostname + '\'' }
					showModal={deletingNode.ndid || deletingNode.ndid === 0}
					disabled={isNodeDeleting}
					onCancel={this.handleCloseModal}
					onConfirm={this.handleConfirmModal}/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		nodeOptions: state.node.nodeOptions,
		nodeList: state.node.nodeList,
		loading: state.node.loading || state.node.nodeOptionsIsLoading,
		isNodeDeleting: state.node.isDeleting
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetNodeOptions: () => dispatch(getNodeOptions()),
		onGetNodeList: (urlParams, cursor, perPage) => dispatch(getNodeList(urlParams, cursor, perPage)),
		onDeleteNodeById: id => dispatch(deleteNodeById(id))
	};
};

NodeList.propTypes = {
	location: PropTypes.object,
	nodeList: PropTypes.object,
	nodeOptions: PropTypes.object,
	loading: PropTypes.bool,
	isNodeDeleting: PropTypes.bool,
	onGetNodeList: PropTypes.func,
	onDeleteNodeById: PropTypes.func,
	onGetNodeOptions: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeList);
