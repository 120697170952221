import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import utils from '../../services/utils';

const AssetRow = ({ fieldId, asset, fieldsOption}) => {
	let row = null;

	switch(fieldsOption[fieldId].type) {
	case 'choice':
		const choices = fieldsOption[fieldId].choices;
		const choicesObj = utils.arrayToObject(choices, 'value', 'display_name');
		row = <td>{choicesObj[asset[fieldId]] || 'N/A'}</td>;
		break;
	default:
		if((fieldId === 'created' || fieldId === 'modified') && asset[fieldId]) {
			row = <td>{moment(1000*asset[fieldId]).format('YYYY-MM-DD') || 'N/A'}</td>;
		} else if(fieldId === 'hostname') {
			row = <td className="sorting_1"><NavLink exact to={`/asset/${asset.asid}`}>{asset.hostname || 'N/A'}</NavLink></td>;
		} else {
			row = <td>{asset[fieldId] || 'N/A'}</td>;
		}
	}

	return row;
};

export default AssetRow;