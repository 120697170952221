import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAssetById, createAsset, getProviderList, getAssetOptions, getAssetLocationByIata } from '../../store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import config from '../../services/config';
import utils from '../../services/utils';
import moment from 'moment';

import Loader from '../../components/UI/Loader/Loader';
import CustomSelect from '../../components/UI/CustomSelect/CustomSelect';
import CustomInput from '../../components/UI/CustomInput/CustomInput';
import CustomTextArea from '../../components/UI/CustomTextArea/CustomTextArea';
import './Asset.css';

const INITIALSTATE = {
	assetDetails: {
		asset: {
			asid: null
		}
	},

	errors: {},
	isTouched: {}
};

class Asset extends Component {
	state = {...INITIALSTATE};

	componentDidMount() {
		document.title = 'Asset - ' + process.env.REACT_APP_PAGE_TITLE;
		const { location: { pathname } } = this.props;
		const asid = pathname.split('/asset/')[1];
		if(asid === 'new') {
			this.props.onGetAssetOptions(null);
		} else {
			this.props.onGetAssetById(asid);
			this.props.onGetAssetOptions(asid);
		}
		this.props.onGetProviderList();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { location: { pathname } } = this.props;
		const asid = pathname.split('/asset/')[1];
		if(!nextProps.isCreating && nextProps.asset && nextProps.asset.asset && (asid !== 'new')) {
			const assetDetails = nextProps.asset;
			document.title = (assetDetails.asset && (assetDetails.asset.hostname + ' - ' + process.env.REACT_APP_PAGE_TITLE)) || ('Asset - ' + process.env.REACT_APP_PAGE_TITLE);
			this.setState({assetDetails: {...assetDetails}});
		}
	}



	renderFields = fields => {
		const { providerList } = this.props;
		const { assetDetails: {asset}, errors, isTouched } = this.state;
		return fields.filter(f => config.ASSET_READONLY_FIELDS.indexOf(f.id) === -1).map(f => {
			if(f.id === 'asid' || f.id === 'created' || f.id === 'modified') {
				return null;
			}
			switch(f.type) {
			case 'choice':
				const optionsList = (f.id === 'pvid') ? ((providerList && providerList.results) || []).map(p => ({value: p.pvid, display_name: p.name})) : f.choices;
				return (
					<div className="col-sm-12 col-md-4 col-lg-3" key={f.id}>
						<CustomSelect
							id={f.id}
							label={f.label}
							inAsset={f.inAsset}
							value={('' + (f.inAsset ? asset[f.id] :  this.state.assetDetails[f.id])) || -1}
							type={f.type}
							disabled={f.read_only}
							required={f.required}
							isTouched={isTouched}
							errors={errors}
							optionList={optionsList}
							onChange={this.handleFieldChange} />
					</div>
				);
			case 'field':
			case 'string':
			case 'float':
			case 'integer':
			case 'decimal':
				return f.id === 'notes' ?
					(
						<div className="col-md-12 col-lg-9" key={f.id}>
							<CustomTextArea
								id={f.id}
								label={f.label}
								inAsset={f.inAsset}
								value={(f.inAsset ? asset[f.id] :  this.state.assetDetails[f.id]) || ''}
								type={f.type}
								disabled={f.read_only}
								max_length={(f.id === 'iata' ? 3 : f.max_length)}
								min_length={f.min_length}
								error_message={this.state.errors[f.id] || ''}
								required={f.required}
								isTouched={isTouched}
								errors={errors}
								onChange={this.handleFieldChange} />
						</div>
					) :
					(
						<div className="col-sm-12 col-md-4 col-lg-3" key={f.id}>
							<CustomInput
								id={f.id}
								label={f.label}
								inAsset={f.inAsset}
								value={(f.inAsset ? asset[f.id] :  this.state.assetDetails[f.id]) || ''}
								type={f.type}
								disabled={f.read_only}
								max_length={(f.id === 'iata' ? 3 : f.max_length)}
								min_length={f.min_length}
								error_message={this.state.errors[f.id] || ''}
								required={f.required}
								isTouched={isTouched}
								errors={errors}
								onChange={this.handleFieldChange} />
						</div>
					);
			case 'date':
				return (
					<div className="col-sm-12 col-md-4 col-lg-3" key={f.id}>
						<div className="form-group">
							<label>{f.label}</label>
							<DatePicker
								id={f.id}
								inAsset={f.inAsset}
								className="form-control"
								dateFormat="yyyy-MM-dd"
								selected={asset[f.id] ? new Date(asset[f.id]) : ''}
								onChangeRaw={e => e.preventDefault()}
								onChange={ date => this.handleDateChange(f.id, date)}
							/>
						</div>
					</div>
				);
			default:
				return null;
			}
		});
	};

	handleFieldChange = (event, type, inAsset) => {
		const { assetDetails, assetDetails: {asset}, isTouched, errors } = this.state;
		const { assetOptions } = this.props;
		const id = event.target.id;
		let value = event.target.value || '';
		const _errors = { ...errors };
		const _asset = { ...asset };
		const _isTouched = {
			...isTouched,
			[id]: true
		};

		if(type === 'choice' && (+value === -1 || value === '')) {
			value = null;
		}

		if((type === 'integer' || type === 'float' || type === 'decimal') && !/^[0-9,.]*$/.test(value)) {
			return;
		}

		const _a = (inAsset ? (assetOptions.asset && assetOptions.asset.children) : assetOptions) || {};
		if(_a[id]) {
			if(_a[id]['required'] && (!value || (value + '').length === 0 || (+value === -1))) {
				_errors[id] = 'required';
			} else if(_a[id]['max_length'] && value.length && value.length > (id === 'iata' ? 3 : _a[id]['max_length'])) {
				_errors[id] = 'max_length';
			}  else if(_a[id]['min_length'] && value.length && value.length < _a[id]['min_length']) {
				_errors[id] = 'min_length';
			} else {
				delete _errors[id];
			}
		}

		const _stateToUpdate = {
			isTouched: _isTouched,
			errors: _errors
		};

		if(inAsset) {
			_asset[id] = value;
			_stateToUpdate.assetDetails = {...assetDetails, asset: _asset};
		} else {
			_stateToUpdate.assetDetails = {...assetDetails, [id]: value};
		}

		this.setState(_stateToUpdate, () => {
			if(id === 'iata') {
				if(value.length < 4) {
					this.props.onGetAssetLocationByIata(value).then(resp => {
						const { assetDetails: {asset}, errors } = this.state;
						const _err = { ...errors };
						let _state = {};

						if(resp.success) {
							const locationData = {...resp};
							const locationDataKeys = Object.keys(locationData) || [];
							for(let i=0; i<locationDataKeys.length; i++) {
								delete _err[locationDataKeys[i]];
							}
							delete _err['iata'];
							delete locationData.airport;
							delete locationData.success;
							delete locationData.text;
							_state = {assetDetails: {...assetDetails, airport: resp.id, airport_name: resp.airport, asset: {...asset, ...locationData}}};

						} else if(resp.text && resp.text.length > 0) {
							_err[id] = {error_message: resp.text};
						}

						if(asset.iata.length >= 4) {
							_err[id] = 'max_length';
						}
						_state.errors = _err;

						this.setState(_state);
					});
				}
			}
		});
	};

	handleDateChange = (id, date) => {
		const { assetDetails, assetDetails: {asset} } = this.state;
		const _asset = {
			...asset,
			[id]: moment(date).format('YYYY-MM-DD')
		};

		this.setState({
			assetDetails: {...assetDetails, asset: _asset}
		});
	};

	handleCreateAsset = (e, redirect, openNewBlank, duplicate) => {
		const { assetDetails, assetDetails: {asset}, errors } = this.state;
		const { history, location: { pathname } } = this.props;
		const asid = pathname.split('/asset/')[1];
		if(Object.keys(errors).length === 0) {
			const dataToSend = { ...assetDetails };

			this.props.onCreateAsset(dataToSend, (asid === 'new' ? null : asid)).then(response => {
				if(response.status && response.status !== 200 && response.status !== 201) {
					return;
				}
				if(redirect) {
					history.push('/asset');
				}
				if(openNewBlank) {
					history.push('/asset/new');
					this.setState(duplicate ? {assetDetails: {...assetDetails, asset: {...asset, asid: null}}} : {...INITIALSTATE});
				}
			});
		}
	};

	render() {
		const { assetOptions, assetOptions: {asset}, isLoading, isCreating, history } = this.props;
		const {
			airport_name,
			assetDetails: {
				asset: {
					asid,
					hostname,
					modified,
					created
				}
			},
			errors
		} = this.state;

		const fieldsMainObj = (asset && asset.children) || {};
		const fieldsMainArray = Object.keys(fieldsMainObj).map((f) => {
			return {
				...fieldsMainObj[f],
				inAsset: true,
				id: f
			};
		});


		const fieldsRestObj = {...assetOptions};
		delete fieldsRestObj.asset;
		const fieldsRestArray = Object.keys(fieldsRestObj).map((f) => {
			return {
				...fieldsRestObj[f],
				id: f
			};
		});

		const groupedFields = utils.groupFields([...fieldsMainArray, ...fieldsRestArray].sort((a) => (a.required ? -1 : 1)), config.ASSETS_FIELDS_GROUPING_PATTERN, 'asid');

		const editMode = asid || asid === 0;
		const buttonDisabled = isCreating || Object.keys(errors).length || utils.isRequiredFieldsEmpty(fieldsMainArray, this.state.assetDetails.asset, 'asid') || utils.isRequiredFieldsEmpty(fieldsRestArray, this.state.assetDetails, 'asid');

		return (
			<Fragment>
				{isLoading ?
					<div className="loader-wrapper">
						<Loader />
					</div> :
					<div className="content-wrapper">
						<div className="container-fluid fields-wrapper">
							<h1 className="h3 mb-2 text-gray-800">{isLoading ? '' : (hostname || 'Create New Asset')}</h1>

							<div className="row mb-4 readonly-fields-container">
								{[...fieldsMainArray, ...fieldsRestArray].filter(f => config.ASSET_READONLY_FIELDS.indexOf(f.id) !== -1 ).map(f => {
									return this.state.assetDetails[f.id] ? <p key={f.id} className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">{f.label}: </span>{this.state.assetDetails[f.id]}</p> :
										this.state.assetDetails.asset[f.id] ? <p key={f.id} className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">{f.label}: </span>{this.state.assetDetails.asset[f.id]}</p> : null;
								})}
								{airport_name ? <p className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">Airport Name: </span>{airport_name}</p> : null}
								{editMode && created ? <p className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">Created: </span>{moment(1000*created).format('YYYY-MM-DD HH:mm')}</p> : null}
								{editMode && modified ? <p className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">Modified: </span>{moment(1000*modified).format('YYYY-MM-DD HH:mm')}</p> : null}
							</div>
							{groupedFields.map(g => {
								return g.fields.length ? (
									<div className="row group-fields" key={g.id}>
										{g.title ? <h4 className="col-lg-12 mb-0">{g.title}</h4> : null}
										{this.renderFields(g.fields)}
									</div>
								) : null;
							})}
						</div>

						<div className="container-fluid">
							<div className="row">
								<div className="col-lg-12">
									<button type="submit"
										className="btn btn-primary mb-2 float-left"
										onClick={() => history.push('/asset')}>Back</button>
									<button type="submit"
										className="btn btn-primary mb-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateAsset(e, true)}>Save</button>
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={this.handleCreateAsset}>{editMode ? 'Save and continue editing' : 'Save and duplicate new'}</button>
									{editMode ?
										<button type="submit"
											className="btn btn-primary mb-2 mr-2 float-right"
											disabled={buttonDisabled}
											onClick={e => this.handleCreateAsset(e, false, true, true)}>Save and duplicate new</button> : null
									}
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateAsset(e, false, true)}>Save and add another</button>
								</div>
							</div>
						</div>
					</div>}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		asset: state.asset.asset,
		assetOptions: state.asset.assetOptions,
		providerList: state.provider.providerList,
		isLoading: state.asset.assetIsLoading || state.asset.assetOptionsIsLoading,
		isCreating: state.asset.isCreating
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetAssetById: id => dispatch(getAssetById(id)),
		onGetAssetOptions: id => dispatch(getAssetOptions(id)),
		onGetProviderList: () => dispatch(getProviderList()),
		onGetAssetLocationByIata: iata => dispatch(getAssetLocationByIata(iata)),
		onCreateAsset: (assetData, id) => dispatch(createAsset(assetData, id))
	};
};

Asset.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	asset: PropTypes.object,
	assetOptions: PropTypes.object,
	providerList: PropTypes.object,
	isLoading: PropTypes.bool,
	isCreating: PropTypes.bool,
	onGetAssetById: PropTypes.func,
	onGetAssetOptions: PropTypes.func,
	onGetProviderList: PropTypes.func,
	onGetAssetLocationByIata: PropTypes.func,
	onCreateAsset: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Asset);