import React from 'react';
import PropTypes from 'prop-types';

const CustomTextArea = ({
	id,
	label,
	inAsset,
	value,
	type,
	rows,
	disabled,
	max_length,
	min_length,
	required,
	isTouched,
	errors,
	onChange
}) => (
	<div className="form-group">
		<label>
			{label}
			{required ? <span>*</span> : null}
		</label>
		<textarea
			id={id}
			className={'form-control ' + (isTouched[id] && errors[id] ? 'field-error' : '')}
			disabled={disabled}
			value={value}
			rows={rows || 10}
			onChange={e => onChange(e, type, inAsset)} />
		{
			isTouched[id] && errors[id] ?
				<div className="form-control-errors-wrapper">
					{ errors[id] && errors[id]['error_message'] && <p>{errors[id]['error_message']}</p> }
					{ errors[id] === 'required' && <p>{label} is required.</p> }
					{ errors[id] === 'max_length' && <p>Max length is {max_length}</p> }
					{ errors[id] === 'min_length' && <p>Min length is {min_length}</p> }
				</div> :
				null
		}
	</div>
);

CustomTextArea.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	inAsset: PropTypes.bool,
	value: PropTypes.string,
	type: PropTypes.string,
	rows: PropTypes.string,
	disabled: PropTypes.bool,
	max_length: PropTypes.number,
	min_length: PropTypes.number,
	required: PropTypes.bool,
	isTouched: PropTypes.object,
	errors: PropTypes.object,
	onChange: PropTypes.func
};

export default CustomTextArea;