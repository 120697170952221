import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import './assets/fonts/fontawesome/css/all.min.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import ErrorBoundary from './components/UI/ErrorBoundary/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import authReducer from './store/reducers/auth';
import subnetReducer from './store/reducers/subnet';
import entityReducer from './store/reducers/entity';
import assetReducer from './store/reducers/asset';
import nodeReducer from './store/reducers/node';
import nodeGroupReducer from './store/reducers/nodeGroup';
import providerReducer from './store/reducers/provider';

Sentry.init({
	dsn: 'https://b20b729bfbb84bb0aa91f0fabc0116a1@sentry.privado.io/6',
	environment: process.env.REACT_APP_ENVIRONMENT
});

const rootReducer = combineReducers({
	auth: authReducer,
	subnet: subnetReducer,
	entity: entityReducer,
	asset: assetReducer,
	node: nodeReducer,
	nodeGroup: nodeGroupReducer,
	provider: providerReducer,
});

const store = createStore(rootReducer, {}, applyMiddleware(thunk));


const app = (
	<Provider store={store}>
		<BrowserRouter>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById('wrapper'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
