const config = {
	ASSETS_FIELDS_GROUPING_PATTERN: [
		{id: 'status', title: '', fields: ['status']},
		{id: 'details', title: 'Details', fields: ['hostname', 'pvid', 'iata', 'icao', 'country', 'city', 'cc', 'airport']},
		{id: 'tech_data', title: 'Technical Data', fields: ['server_type', 'cpu', 'pool', 'ffa', 'net_ip', 'remote_gw', 'remote_cidr',  'remote_user', 'remote_pass', 'bandwidth', 'uplink', 'remote_proto', 'remote_uri']},
		{id: 'fin_data', title: 'Financial Data', fields: ['cost', 'currency', 'invoice_num', 'invoice_date', 'payment_date', 'payment_method', 'payment_freq', 'payment_amt', 'payment_overage']},
		{id: 'notes', title: '', fields: ['notes']}
	],
	ASSET_READONLY_FIELDS: ['country', 'city', 'cc', 'icao', 'airport'],

	NODE_FIELDS_GROUPING_PATTERN: [
		{id: 'status', title: '', fields: ['status']},
		{id: 'details', title: 'Details', fields: ['hostname', 'asid', 'enid', 'snid', 'net_gw', 'net_ip', 'pop', 'rgid', 'visible']},
		{id: 'tech_data', title: 'Technical Data', fields: ['socks5', 'net_nat_beg', 'net_nat_end', 'capacity', 'cityscore', 'countryscore']},
		{id: 'fin_data', title: 'Financial Data', fields: []},
		{id: 'notes', title: '', fields: ['notes']}
	],
	NODE_READONLY_FIELDS: [],

	NODE_GROUP_FIELDS_GROUPING_PATTERN: [
		{id: 'details', title: '', fields: ['name', 'is_enabled', 'nodes']}
	],
	NODE_GROUP_READONLY_FIELDS: []
};

export default config;