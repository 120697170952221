import axios from 'axios';


const vpnDbApi = axios.create({
	baseURL: `${process.env.REACT_APP_API}/api/v1/`,
	headers: { 'Accept': 'application/json' }
});

const API = {
	/* Auth */
	login: authData => {
		return vpnDbApi.post('login', authData);
	},

	logout: token => {
		return vpnDbApi.post('logout', null, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},

	/* Subnet */
	getSubnetList: (token, ordering, cursor, page_size = 100000) => {
		let url = '';
		if(cursor && (cursor !== 'initial')) {
			url = cursor;
		} else {
			url += 'subnet';
			if (page_size) {
				url += `?page_size=${page_size}`;
			}
			if (ordering) {
				url += (page_size ? '&' : '?') + ordering;
			}
		}

		return vpnDbApi.get(url, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	getSubnetById: (token, id) => {
		return vpnDbApi.get(`subnet/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	createSubnet: (token, subnetData, id) => {
		return id ?
			vpnDbApi.put(`subnet/${id}`, subnetData, {
				headers: { 'Authorization': 'Token ' + token }
			}) :
			vpnDbApi.post('subnet', subnetData, {
				headers: { 'Authorization': 'Token ' + token }
			});
	},
	deleteSubnetById: (token, id) => {
		return vpnDbApi.delete(`subnet/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},

	/* Entity */
	getEntityList: (token, ordering, cursor, page_size = 100000) => {
		let url = '';
		if(cursor && (cursor !== 'initial')) {
			url = cursor;
		} else {
			url += 'entity';
			if (page_size) {
				url += `?page_size=${page_size}`;
			}
			if (ordering) {
				url += (page_size ? '&' : '?') + ordering;
			}
		}

		return vpnDbApi.get(url, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	getEntityById: (token, id) => {
		return vpnDbApi.get(`entity/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	createEntity: (token, entityData, id) => {
		return id ?
			vpnDbApi.put(`entity/${id}`, entityData, {
				headers: { 'Authorization': 'Token ' + token }
			}) :
			vpnDbApi.post('entity', entityData, {
				headers: { 'Authorization': 'Token ' + token }
			});
	},
	deleteEntityById: (token, id) => {
		return vpnDbApi.delete(`entity/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},

	/* Asset */
	getAssetList: (token, ordering, cursor, page_size = 100000) => {
		let url = '';
		if(cursor && (cursor !== 'initial')) {
			url = cursor;
		} else {
			url += 'asset_details';
			if (page_size) {
				url += `?page_size=${page_size}`;
			}
			if (ordering) {
				url += (page_size ? '&' : '?') + ordering;
			}
		}

		return vpnDbApi.get(url, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	getAssetById: (token, id) => {
		return vpnDbApi.get(`asset_details/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	getAssetOptions: (token, id) => {
		return vpnDbApi.options('asset_details' + (id ? ('/' + id) : ''), {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	getAssetLocationByIata: (token, iata) => {
		return vpnDbApi.post('get_location_by_iata', `iata=${iata}`, {
			headers: {
				'Authorization': 'Token ' + token,
				'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
			}
		});
	},
	createAsset: (token, assetData, id) => {
		return id ?
			vpnDbApi.put(`asset_details/${id}`, assetData, {
				headers: { 'Authorization': 'Token ' + token }
			}) :
			vpnDbApi.post('asset_details', assetData, {
				headers: { 'Authorization': 'Token ' + token }
			});
	},
	deleteAssetById: (token, id) => {
		return vpnDbApi.delete(`asset_details/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},

	/* Node */
	getNodeList: (token, ordering, cursor, page_size = 100000) => {
		let url = '';
		if(cursor && (cursor !== 'initial')) {
			url = cursor;
		} else {
			url += 'node';
			if (page_size) {
				url += `?page_size=${page_size}`;
			}
			if (ordering) {
				url += (page_size ? '&' : '?') + ordering;
			}
		}

		return vpnDbApi.get(url, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	getNodeOptions: (token, id) => {
		return vpnDbApi.options('node' + (id ? ('/' + id) : ''), {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	getNodeById: (token, id) => {
		return vpnDbApi.get(`node/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	createNode: (token, nodeData, id) => {
		return id ?
			vpnDbApi.put(`node/${id}`, nodeData, {
				headers: { 'Authorization': 'Token ' + token }
			}) :
			vpnDbApi.post('node', nodeData, {
				headers: { 'Authorization': 'Token ' + token }
			});
	},
	deleteNodeById: (token, id) => {
		return vpnDbApi.delete(`node/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},

	/* Provider */
	getProviderList: (token, ordering, cursor, page_size = 100000) => {
		let url = '';
		if(cursor && (cursor !== 'initial')) {
			url = cursor;
		} else {
			url += 'provider';
			if (page_size) {
				url += `?page_size=${page_size}`;
			}
			if (ordering) {
				url += (page_size ? '&' : '?') + ordering;
			}
		}

		return vpnDbApi.get(url, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	getProviderById: (token, id) => {
		return vpnDbApi.get(`provider/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	createProvider: (token, providerData, id) => {
		return id ?
			vpnDbApi.put(`provider/${id}`, providerData, {
				headers: { 'Authorization': 'Token ' + token }
			}) :
			vpnDbApi.post('provider', providerData, {
				headers: { 'Authorization': 'Token ' + token }
			});
	},
	deleteProviderById: (token, id) => {
		return vpnDbApi.delete(`provider/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},

	/* Node Group */
	getNodeGroupList: (token, ordering, cursor, page_size = 100000) => {
		let url = '';
		if(cursor && (cursor !== 'initial')) {
			url = cursor;
		} else {
			url += 'node_group';
			if (page_size) {
				url += `?page_size=${page_size}`;
			}
			if (ordering) {
				url += (page_size ? '&' : '?') + ordering;
			}
		}

		return vpnDbApi.get(url, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	getNodeGroupOptions: (token, id) => {
		return vpnDbApi.options('node_group' + (id ? ('/' + id) : ''), {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	getNodeGroupById: (token, id) => {
		return vpnDbApi.get(`node_group/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	},
	createNodeGroup: (token, nodeData, id) => {
		return id ?
			vpnDbApi.put(`node_group/${id}`, nodeData, {
				headers: { 'Authorization': 'Token ' + token }
			}) :
			vpnDbApi.post('node_group', nodeData, {
				headers: { 'Authorization': 'Token ' + token }
			});
	},
	deleteNodeGroupById: (token, id) => {
		return vpnDbApi.delete(`node_group/${id}`, {
			headers: { 'Authorization': 'Token ' + token }
		});
	}
};

export default API;
