import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import utils from '../../services/utils';

const NodeRow = ({ fieldId, node, fieldsOption}) => {
	let row = null;

	if(fieldId === 'asid' || fieldId === 'enid' || fieldId === 'snid') {fieldsOption[fieldId].type = 'choice';}

	switch(fieldsOption[fieldId].type) {
	case 'choice':
		const choices = fieldsOption[fieldId].choices;
		const choicesObj = utils.arrayToObject(choices, 'value', 'display_name');
		row = <td>{choicesObj[node[fieldId]] || 'N/A'}</td>;
		break;
	default:
		if((fieldId === 'created' || fieldId === 'modified') && node[fieldId]) {
			row = <td>{moment(1000*node[fieldId]).format('YYYY-MM-DD') || 'N/A'}</td>;
		} else if(fieldId === 'hostname') {
			row = <td className="sorting_1"><NavLink exact to={`/node/${node.ndid}`}>{node.hostname || 'N/A'}</NavLink></td>;
		} else {
			row = <td>{node[fieldId] || 'N/A'}</td>;
		}
	}

	return row;
};

export default NodeRow;
