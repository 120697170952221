import modules from './modules';

import {
	GET_NODE_LIST_START,
	GET_NODE_LIST_SUCCESS,
	GET_NODE_LIST_FAIL,

	GET_NODE_START,
	GET_NODE_SUCCESS,
	GET_NODE_FAIL,

	CREATE_NODE_START,
	CREATE_NODE_SUCCESS,
	CREATE_NODE_FAIL,

	DELETE_NODE_START,
	DELETE_NODE_SUCCESS,
	DELETE_NODE_FAIL,

	GET_NODE_OPTIONS_START,
	GET_NODE_OPTIONS_SUCCESS,
	GET_NODE_OPTIONS_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

const { API, utils, toast } = modules;

/* node list start */
export const getNodeListStart = () => {
	return {
		type: GET_NODE_LIST_START
	};
};

export const getNodeListSuccess = nodeList => {
	return {
		type: GET_NODE_LIST_SUCCESS,
		nodeList
	};
};

export const getNodeListFail = () => {
	return {
		type: GET_NODE_LIST_FAIL
	};
};

export const getNodeList = (ordering, cursor, perPage) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getNodeListStart());
		API.getNodeList(userData.token, ordering, cursor, perPage)
			.then(response => {
				const nodeList = (response && response.data) || {};
				dispatch(getNodeListSuccess(nodeList));
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				dispatch(getNodeListFail());
			});

	};
};
/* node list end */

/* node start */
export const getNodeStart = () => {
	return {
		type: GET_NODE_START,
		node: []
	};
};

export const getNodeSuccess = node => {
	return {
		type: GET_NODE_SUCCESS,
		node
	};
};

export const getNodeFail = () => {
	return {
		type: GET_NODE_FAIL
	};
};

export const getNodeById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getNodeStart());
		return API.getNodeById(userData.token, id)
			.then(response => {
				dispatch(getNodeSuccess(response.data));
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				dispatch(getNodeFail());
			});

	};
};
/* node end */

/* create/edit node start */
export const createNodeStart = () => {
	return {
		type: CREATE_NODE_START
	};
};

export const createNodeSuccess = node => {
	return {
		type: CREATE_NODE_SUCCESS,
		node
	};
};

export const createNodeFail = () => {
	return {
		type: CREATE_NODE_FAIL
	};
};

export const createNode = (nodeData, id) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(createNodeStart());
		return API.createNode(userData.token, nodeData, id)
			.then(response => {
				dispatch(createNodeSuccess(response.data));
				toast.success(`Node ${(id == null) ? 'created' : 'edited'} successfully`, {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				let message = 'Something went wrong';
				const resp = (error && error.response) || '';
				if(resp.data && typeof resp.data !== 'string') {
					const errors = resp.data;
					for(var o in errors) {
						message = (errors[o] && errors[o][0]) || 'Something went wrong';
					}
				}
				utils.handleError(error, dispatch, UPDATE_USER_DATA, message);
				dispatch(createNodeFail());
				return (error && error.response) || {};
			});

	};
};
/* create/edit node end */

/* delete node start */
export const deleteNodeStart = () => {
	return {
		type: DELETE_NODE_START
	};
};

export const deleteNodeSuccess = nodeList => {
	return {
		type: DELETE_NODE_SUCCESS,
		nodeList
	};
};

export const deleteNodeFail = () => {
	return {
		type: DELETE_NODE_FAIL
	};
};

export const deleteNodeById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(deleteNodeStart());
		return API.deleteNodeById(userData.token, id)
			.then(() => {
				let _nodeList = getState().node.nodeList || {};
				_nodeList.results = (_nodeList.results || []).filter(n => n.ndid !== id);
				dispatch(deleteNodeSuccess(_nodeList));
				toast.success('Node deleted successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return id;
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA, 'Something went wrong deleting node');
				dispatch(deleteNodeFail());
			});

	};
};
/* delete node end */

/* get node options start */
export const getNodeOptionsStart = () => {
	return {
		type: GET_NODE_OPTIONS_START
	};
};

export const getNodeOptionsSuccess = nodeOptions => {
	return {
		type: GET_NODE_OPTIONS_SUCCESS,
		nodeOptions
	};
};

export const getNodeOptionsFail = () => {
	return {
		type: GET_NODE_OPTIONS_FAIL
	};
};

export const getNodeOptions = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getNodeOptionsStart());
		return API.getNodeOptions(userData.token, id)
			.then(response => {
				const data = (response.data && response.data.actions && response.data.actions[(id ? 'PUT' : 'POST')]) || {};
				dispatch(getNodeOptionsSuccess(data));
			})
			.catch( error => {
				utils.handleErrors(error, 'node', dispatch, UPDATE_USER_DATA);
				dispatch(getNodeOptionsFail());
			});
	};
};
/* get node options end */