import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';

const utility = {

	setDataToStorage: (key, value) => {
		localStorage[key] = JSON.stringify(value);
	},

	getDataFromStorage: (key) => {
		return localStorage[key] ? JSON.parse(localStorage[key]) : null;
	},

	deleteDataFromStorage: (key) => {
		localStorage.removeItem(key);
	},

	updateObject: (oldObject, updatedProperties) => {
		return {
			...oldObject,
			...updatedProperties
		};
	},

	isEmptyObject: (obj) => {
		for(var key in obj) {
			if(obj.hasOwnProperty(key))
				return false;
		}
		return true;
	},

	isJson: (str) => {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	},
	arrayToObject: (arr, key, value) => {
		const _arr = arr || [];
		const _obj = {};
		for(let i = 0; i < _arr.length; i++) {
			const _item = _arr[i];
			_obj[_item[key || 'id']] = _item[value || 'value'];
		}
		return _obj;
	},
	processPageChange: (currentCursor, urlParams, page) => {
		if(currentCursor) {
			const urlParts = currentCursor.split('?');
			const paramsObj = queryString.parse(urlParts);
			currentCursor = urlParts[0] + '?' + queryString.stringify({...paramsObj, page});
		} else if(urlParams) {
			const paramsObj = queryString.parse(urlParams);
			urlParams = queryString.stringify({...paramsObj, page});
		} else {
			urlParams = 'page=' + page;
		}

		return {urlParams, currentCursor, activePage: page};
	},
	processOrderBy: (currentCursor, urlParams, sortBy) => {
		if(currentCursor) {
			let urlParts = currentCursor.split('?');
			const paramsObj = queryString.parse(urlParts);
			const _ordering = ((paramsObj.ordering && paramsObj.ordering.indexOf(sortBy) === 0) ? '-' : '') + sortBy;
			currentCursor = urlParts[0] + '?' + queryString.stringify({...paramsObj, ordering: _ordering});
		} else if(urlParams) {
			const paramsObj = queryString.parse(urlParams);
			const _ordering = ((paramsObj.ordering && paramsObj.ordering.indexOf(sortBy) === 0) ? '-' : '') + sortBy;
			urlParams = queryString.stringify({...paramsObj, ordering: _ordering});
		} else {
			urlParams = 'ordering=' + sortBy;
		}

		return {urlParams, currentCursor};
	},
	processSearchBy: (currentCursor, urlParams, search) => {
		if(currentCursor) {
			const urlParts = currentCursor.split('?');
			const paramsObj = queryString.parse(urlParts);
			currentCursor = urlParts[0] + '?' + queryString.stringify({...paramsObj, search});
		} else if(urlParams) {
			const paramsObj = queryString.parse(urlParams);
			delete paramsObj.page;

			if(search) {
				paramsObj.search = search;
			} else {
				delete paramsObj.search;
			}
			urlParams = (Object.keys(paramsObj).length) ? queryString.stringify(paramsObj) : '';
		} else if(search) {
			urlParams = queryString.stringify({search});
		}

		return {urlParams, currentCursor};
	},
	isRequiredFieldsEmpty: (fieldsArray, fields, id) => {
		let disabled = false;
		for(let i=0; i<fieldsArray.length; i++) {
			const fieldValue = fields[fieldsArray[i].id];
			if((fieldsArray[i].id !== id) && (typeof fieldValue === 'undefined' || ('' + fieldValue).length === 0 || fieldValue === -1) && fieldsArray[i].required) {
				disabled = true;
			}
		}
		return disabled;
	},
	groupFields: (fieldsArray, pattern, id) => {
		const _groupedFields = [];
		const _fieldsArray = fieldsArray.map(f => ({...f}));

		for(let i=0; i<pattern.length; i++) {
			_groupedFields.push({...pattern[i]});
			_groupedFields[i].fields = [...pattern[i].fields];

			for(let j=0; j<_groupedFields[i].fields.length; j++) {
				for(let k=0; k<_fieldsArray.length; k++) {
					if(pattern[i].fields[j] === _fieldsArray[k].id) {
						_groupedFields[i].fields[j] = _fieldsArray[k];
						_fieldsArray.splice(k, 1);
						k--;
					}
				}
			}
		}

		const _additionalFields = [..._fieldsArray.filter(f => (f.id !== id && f.id !== 'created' && f.id !== 'modified'))];

		if(_additionalFields.length) {
			_groupedFields.push({id: 'additional', title: 'Additional fields', fields: _additionalFields});
		}

		return _groupedFields;
	},
	handleError: (error, dispatch, UPDATE_USER_DATA, message) => {
		const status = error.response && error.response.status;
		let _message = message || 'Something went wrong fetching data';

		if(status === 401) {
			_message = 'Not Authorized. Please login!';
			localStorage.removeItem('VPNDB_USER_DATA');
			dispatch({
				type: UPDATE_USER_DATA,
				user: {}
			});
		} else if(status >= 500) {
			_message = 'Not available to public users';
		}
		toast.error(_message, {
			position: toast.POSITION.TOP_RIGHT
		});
		Sentry.captureException(error);
		console.log(error);
	},
	handleErrors: (error, itemType, dispatch, UPDATE_USER_DATA, message) => {
		const status = error.response && error.response.status;
		let _message = message || 'Something went wrong fetching data';

		const errorsData = error.response && error.response.data;
		const errorsObj = (errorsData && errorsData[itemType]) || {};
		const errorsArray = Object.keys(errorsObj);

		if(errorsData && errorsData[0]) {
			_message = errorsData[0];
			toast.error(_message, {
				position: toast.POSITION.TOP_RIGHT
			});

		} else if(errorsArray.length) {
			for(let i=0; i<errorsArray.length; i++) {
				const fieldErrorArray = errorsObj[errorsArray[i]];
				for(let j=0;j<fieldErrorArray.length; j++) {
					toast.error((errorsArray[i] + ': ' + fieldErrorArray[j]), {
						position: toast.POSITION.TOP_RIGHT
					});
				}
			}
		} else {
			if(status === 401) {
				_message = 'Not Authorized. Please login!';
				localStorage.removeItem('VPNDB_USER_DATA');
				dispatch({
					type: UPDATE_USER_DATA,
					user: {}
				});
			} else if(status >= 500) {
				_message = 'Not available to public users';
			}
			toast.error(_message, {
				position: toast.POSITION.TOP_RIGHT
			});
		}

		Sentry.captureException(error);
		console.log(error);
	}
};

export default utility;