import modules from './modules';

import {
	GET_ASSET_LIST_START,
	GET_ASSET_LIST_SUCCESS,
	GET_ASSET_LIST_FAIL,

	GET_ASSET_START,
	GET_ASSET_SUCCESS,
	GET_ASSET_FAIL,

	CREATE_ASSET_START,
	CREATE_ASSET_SUCCESS,
	CREATE_ASSET_FAIL,

	DELETE_ASSET_START,
	DELETE_ASSET_SUCCESS,
	DELETE_ASSET_FAIL,

	GET_ASSET_OPTIONS_START,
	GET_ASSET_OPTIONS_SUCCESS,
	GET_ASSET_OPTIONS_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

const { API, utils, toast} = modules;

/* asset list start */
export const getAssetListStart = () => {
	return {
		type: GET_ASSET_LIST_START
	};
};

export const getAssetListSuccess = assetList => {
	return {
		type: GET_ASSET_LIST_SUCCESS,
		assetList
	};
};

export const getAssetListFail = () => {
	return {
		type: GET_ASSET_LIST_FAIL
	};
};

export const getAssetList = (ordering, cursor, perPage) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getAssetListStart());
		return API.getAssetList(userData.token, ordering, cursor, perPage)
			.then(response => {
				const assetsList = (response && response.data) || {};
				dispatch(getAssetListSuccess(assetsList));
				return assetsList;
			})
			.catch( error => {
				utils.handleErrors(error, 'asset', dispatch, UPDATE_USER_DATA);
				dispatch(getAssetListFail());
				return {};
			});
	};
};
/* asset list end */

/* asset start */
export const getAssetStart = () => {
	return {
		type: GET_ASSET_START,
		asset: []
	};
};

export const getAssetSuccess = asset => {
	return {
		type: GET_ASSET_SUCCESS,
		asset
	};
};

export const getAssetFail = () => {
	return {
		type: GET_ASSET_FAIL
	};
};

export const getAssetById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getAssetStart());
		return API.getAssetById(userData.token, id)
			.then(response => {
				dispatch(getAssetSuccess(response.data));
			})
			.catch( error => {
				utils.handleErrors(error, 'asset', dispatch, UPDATE_USER_DATA);
				dispatch(getAssetFail());
			});
	};
};
/* asset end */

/* create/edit asset start */
export const createAssetStart = () => {
	return {
		type: CREATE_ASSET_START
	};
};

export const createAssetSuccess = asset => {
	return {
		type: CREATE_ASSET_SUCCESS,
		asset
	};
};

export const createAssetFail = () => {
	return {
		type: CREATE_ASSET_FAIL
	};
};

export const createAsset = (assetData, id) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(createAssetStart());
		return API.createAsset(userData.token, assetData, id)
			.then(response => {
				const assetDetails = response.data;
				if(id && assetDetails.asset) {
					assetDetails.asset.asid = id;
				}
				dispatch(createAssetSuccess(response.data));
				toast.success(`Asset ${(id == null) ? 'created' : 'edited'} successfully`, {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				let message = 'Something went wrong';
				const resp = (error && error.response) || '';
				if(resp.data && typeof resp.data !== 'string') {
					const errors = resp.data;
					for(var o in errors) {
						message = (errors[o] && errors[o][0]) || 'Something went wrong';
					}
				}
				utils.handleErrors(error, 'asset', dispatch, UPDATE_USER_DATA, message);
				dispatch(createAssetFail());
				return (error && error.response) || {};
			});
	};
};
/* create/edit asset end */

/* delete asset start */
export const deleteAssetStart = () => {
	return {
		type: DELETE_ASSET_START
	};
};

export const deleteAssetSuccess = assetList => {
	return {
		type: DELETE_ASSET_SUCCESS,
		assetList
	};
};

export const deleteAssetFail = () => {
	return {
		type: DELETE_ASSET_FAIL
	};
};

export const deleteAssetById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(deleteAssetStart());
		return API.deleteAssetById(userData.token, id)
			.then(() => {
				let _assetList = getState().asset.assetList || {};
				_assetList.results = (_assetList.results || []).filter(a => a.asset.asid !== id);
				dispatch(deleteAssetSuccess(_assetList));
				toast.success('Asset deleted successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return id;
			})
			.catch( error => {
				utils.handleErrors(error, 'asset', dispatch, UPDATE_USER_DATA, 'Something went wrong deleting asset');
				dispatch(deleteAssetFail());
			});
	};
};
/* delete asset end */

/* get asset options start */
export const getAssetOptionsStart = () => {
	return {
		type: GET_ASSET_OPTIONS_START
	};
};

export const getAssetOptionsSuccess = assetOptions => {
	return {
		type: GET_ASSET_OPTIONS_SUCCESS,
		assetOptions
	};
};

export const getAssetOptionsFail = () => {
	return {
		type: GET_ASSET_OPTIONS_FAIL
	};
};

export const getAssetOptions = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getAssetOptionsStart());
		return API.getAssetOptions(userData.token, id)
			.then(response => {
				const data = (response.data && response.data.actions && response.data.actions[(id ? 'PUT' : 'POST')]) || {};
				dispatch(getAssetOptionsSuccess(data));
			})
			.catch( error => {
				utils.handleErrors(error, 'asset', dispatch, UPDATE_USER_DATA);
				dispatch(getAssetOptionsFail());
			});
	};
};
/* get asset options end */



/* get asset location by iata start */
export const getAssetLocationByIata = iata => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		return API.getAssetLocationByIata(userData.token, iata)
			.then(response => {
				return response.data || {};
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				return {};
			});
	};
};
/* get asset location by iata end */