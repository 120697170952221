import modules from './modules';

import {
	GET_NODE_GROUP_LIST_START,
	GET_NODE_GROUP_LIST_SUCCESS,
	GET_NODE_GROUP_LIST_FAIL,

	GET_NODE_GROUP_START,
	GET_NODE_GROUP_SUCCESS,
	GET_NODE_GROUP_FAIL,

	CREATE_NODE_GROUP_START,
	CREATE_NODE_GROUP_SUCCESS,
	CREATE_NODE_GROUP_FAIL,

	DELETE_NODE_GROUP_START,
	DELETE_NODE_GROUP_SUCCESS,
	DELETE_NODE_GROUP_FAIL,

	GET_NODE_GROUP_OPTIONS_START,
	GET_NODE_GROUP_OPTIONS_SUCCESS,
	GET_NODE_GROUP_OPTIONS_FAIL,

	UPDATE_USER_DATA
} from './actionTypes';

const { API, utils, toast } = modules;

/* node group list start */
export const getNodeGroupListStart = () => {
	return {
		type: GET_NODE_GROUP_LIST_START
	};
};

export const getNodeGroupListSuccess = nodeGroupList => {
	return {
		type: GET_NODE_GROUP_LIST_SUCCESS,
		nodeGroupList
	};
};

export const getNodeGroupListFail = () => {
	return {
		type: GET_NODE_GROUP_LIST_FAIL
	};
};

export const getNodeGroupList = (ordering, cursor, perPage) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getNodeGroupListStart());
		API.getNodeGroupList(userData.token, ordering, cursor, perPage)
			.then(response => {
				const nodeList = (response && response.data) || {};
				dispatch(getNodeGroupListSuccess(nodeList));
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				dispatch(getNodeGroupListFail());
			});

	};
};
/* node group list end */

/* node group start */
export const getNodeGroupStart = () => {
	return {
		type: GET_NODE_GROUP_START
	};
};

export const getNodeGroupSuccess = nodeGroup => {
	return {
		type: GET_NODE_GROUP_SUCCESS,
		nodeGroup
	};
};

export const getNodeGroupFail = () => {
	return {
		type: GET_NODE_GROUP_FAIL
	};
};

export const getNodeGroupById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getNodeGroupStart());
		return API.getNodeGroupById(userData.token, id)
			.then(response => {
				dispatch(getNodeGroupSuccess(response.data));
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA);
				dispatch(getNodeGroupFail());
			});

	};
};
/* node group end */

/* create/edit node group start */
export const createNodeGroupStart = () => {
	return {
		type: CREATE_NODE_GROUP_START
	};
};

export const createNodeGroupSuccess = () => {
	return {
		type: CREATE_NODE_GROUP_SUCCESS
	};
};

export const createNodeGroupFail = () => {
	return {
		type: CREATE_NODE_GROUP_FAIL
	};
};

export const createNodeGroup = (nodeGroupData, id) => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(createNodeGroupStart());
		return API.createNodeGroup(userData.token, nodeGroupData, id)
			.then(response => {
				dispatch(createNodeGroupSuccess(response.data));
				toast.success(`Node Group ${(id == null) ? 'created' : 'edited'} successfully`, {
					position: toast.POSITION.TOP_RIGHT
				});
				return response;
			})
			.catch( error => {
				let message = 'Something went wrong';
				const resp = (error && error.response) || '';
				if(resp.data && typeof resp.data !== 'string') {
					const errors = resp.data;
					for(var o in errors) {
						message = (errors[o] && errors[o][0]) || 'Something went wrong';
					}
				}
				utils.handleError(error, dispatch, UPDATE_USER_DATA, message);
				dispatch(createNodeGroupFail());
				return (error && error.response) || {};
			});

	};
};
/* create/edit node group end */

/* delete node group start */
export const deleteNodeGroupStart = () => {
	return {
		type: DELETE_NODE_GROUP_START
	};
};

export const deleteNodeGroupSuccess = nodeGroupList => {
	return {
		type: DELETE_NODE_GROUP_SUCCESS,
		nodeGroupList
	};
};

export const deleteNodeGroupFail = () => {
	return {
		type: DELETE_NODE_GROUP_FAIL
	};
};

export const deleteNodeGroupById = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(deleteNodeGroupStart());
		return API.deleteNodeGroupById(userData.token, id)
			.then(() => {
				let _nodeGroupList = getState().nodeGroup.nodeGroupList || {};
				_nodeGroupList.results = (_nodeGroupList.results || []).filter(n => n.id !== id);
				dispatch(deleteNodeGroupSuccess(_nodeGroupList));
				toast.success('Node Group deleted successfully', {
					position: toast.POSITION.TOP_RIGHT
				});
				return id;
			})
			.catch( error => {
				utils.handleError(error, dispatch, UPDATE_USER_DATA, 'Something went wrong deleting node');
				dispatch(deleteNodeGroupFail());
			});

	};
};
/* delete node group end */

/* get node group options start */
export const getNodeGroupOptionsStart = () => {
	return {
		type: GET_NODE_GROUP_OPTIONS_START
	};
};

export const getNodeGroupOptionsSuccess = nodeGroupOptions => {
	return {
		type: GET_NODE_GROUP_OPTIONS_SUCCESS,
		nodeGroupOptions
	};
};

export const getNodeGroupOptionsFail = () => {
	return {
		type: GET_NODE_GROUP_OPTIONS_FAIL
	};
};

export const getNodeGroupOptions = id => {
	return (dispatch, getState) => {
		const userData = getState().auth.user;

		dispatch(getNodeGroupOptionsStart());
		return API.getNodeGroupOptions(userData.token, id)
			.then(response => {
				const data = (response.data && response.data.actions && response.data.actions[(id ? 'PUT' : 'POST')]) || {};
				dispatch(getNodeGroupOptionsSuccess(data));
			})
			.catch( error => {
				utils.handleErrors(error, 'nodegroup', dispatch, UPDATE_USER_DATA);
				dispatch(getNodeGroupOptionsFail());
			});
	};
};
/* get node group options end */