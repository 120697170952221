/* Auth */
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

/* Subnet List */
export const GET_SUBNET_LIST_START = 'GET_SUBNET_LIST_START';
export const GET_SUBNET_LIST_SUCCESS = 'GET_SUBNET_LIST_SUCCESS';
export const GET_SUBNET_LIST_FAIL = 'GET_SUBNET_LIST_FAIL';

export const GET_SUBNET_START = 'GET_SUBNET_START';
export const GET_SUBNET_SUCCESS = 'GET_SUBNET_SUCCESS';
export const GET_SUBNET_FAIL = 'GET_SUBNET_FAIL';

export const CREATE_SUBNET_START = 'CREATE_SUBNET_START';
export const CREATE_SUBNET_SUCCESS = 'CREATE_SUBNET_SUCCESS';
export const CREATE_SUBNET_FAIL = 'CREATE_SUBNET_FAIL';

export const DELETE_SUBNET_START = 'DELETE_SUBNET_START';
export const DELETE_SUBNET_SUCCESS = 'DELETE_SUBNET_SUCCESS';
export const DELETE_SUBNET_FAIL = 'DELETE_SUBNET_FAIL';

/* Entity List */
export const GET_ENTITY_LIST_START = 'GET_ENTITY_LIST_START';
export const GET_ENTITY_LIST_SUCCESS = 'GET_ENTITY_LIST_SUCCESS';
export const GET_ENTITY_LIST_FAIL = 'GET_ENTITY_LIST_FAIL';

export const GET_ENTITY_START = 'GET_ENTITY_START';
export const GET_ENTITY_SUCCESS = 'GET_ENTITY_SUCCESS';
export const GET_ENTITY_FAIL = 'GET_ENTITY_FAIL';

export const CREATE_ENTITY_START = 'CREATE_ENTITY_START';
export const CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';
export const CREATE_ENTITY_FAIL = 'CREATE_ENTITY_FAIL';

export const DELETE_ENTITY_START = 'DELETE_ENTITY_START';
export const DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS';
export const DELETE_ENTITY_FAIL = 'DELETE_ENTITY_FAIL';

/* Asset List */
export const GET_ASSET_LIST_START = 'GET_ASSET_LIST_START';
export const GET_ASSET_LIST_SUCCESS = 'GET_ASSET_LIST_SUCCESS';
export const GET_ASSET_LIST_FAIL = 'GET_ASSET_LIST_FAIL';

export const GET_ASSET_START = 'GET_ASSET_START';
export const GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS';
export const GET_ASSET_FAIL = 'GET_ASSET_FAIL';

export const CREATE_ASSET_START = 'CREATE_ASSET_START';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_FAIL = 'CREATE_ASSET_FAIL';

export const DELETE_ASSET_START = 'DELETE_ASSET_START';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_FAIL = 'DELETE_ASSET_FAIL';

export const GET_ASSET_OPTIONS_START = 'GET_ASSET_OPTIONS_START';
export const GET_ASSET_OPTIONS_SUCCESS = 'GET_ASSET_OPTIONS_SUCCESS';
export const GET_ASSET_OPTIONS_FAIL = 'GET_ASSET_OPTIONS_FAIL';

/* Node List */
export const GET_NODE_LIST_START = 'GET_NODE_LIST_START';
export const GET_NODE_LIST_SUCCESS = 'GET_NODE_LIST_SUCCESS';
export const GET_NODE_LIST_FAIL = 'GET_NODE_LIST_FAIL';

export const GET_NODE_START = 'GET_NODE_START';
export const GET_NODE_SUCCESS = 'GET_NODE_SUCCESS';
export const GET_NODE_FAIL = 'GET_NODE_FAIL';

export const CREATE_NODE_START = 'CREATE_NODE_START';
export const CREATE_NODE_SUCCESS = 'CREATE_NODE_SUCCESS';
export const CREATE_NODE_FAIL = 'CREATE_NODE_FAIL';

export const DELETE_NODE_START = 'DELETE_NODE_START';
export const DELETE_NODE_SUCCESS = 'DELETE_NODE_SUCCESS';
export const DELETE_NODE_FAIL = 'DELETE_NODE_FAIL';

export const GET_NODE_OPTIONS_START = 'GET_NODE_OPTIONS_START';
export const GET_NODE_OPTIONS_SUCCESS = 'GET_NODE_OPTIONS_SUCCESS';
export const GET_NODE_OPTIONS_FAIL = 'GET_NODE_OPTIONS_FAIL';

/* Provider List */
export const GET_PROVIDER_LIST_START = 'GET_PROVIDER_LIST_START';
export const GET_PROVIDER_LIST_SUCCESS = 'GET_PROVIDER_LIST_SUCCESS';
export const GET_PROVIDER_LIST_FAIL = 'GET_PROVIDER_LIST_FAIL';

export const GET_PROVIDER_START = 'GET_PROVIDER_START';
export const GET_PROVIDER_SUCCESS = 'GET_PROVIDER_SUCCESS';
export const GET_PROVIDER_FAIL = 'GET_PROVIDER_FAIL';

export const CREATE_PROVIDER_START = 'CREATE_PROVIDER_START';
export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export const CREATE_PROVIDER_FAIL = 'CREATE_PROVIDER_FAIL';

export const DELETE_PROVIDER_START = 'DELETE_PROVIDER_START';
export const DELETE_PROVIDER_SUCCESS = 'DELETE_PROVIDER_SUCCESS';
export const DELETE_PROVIDER_FAIL = 'DELETE_PROVIDER_FAIL';

/* Node Group List */
export const GET_NODE_GROUP_LIST_START = 'GET_NODE_GROUP_LIST_START';
export const GET_NODE_GROUP_LIST_SUCCESS = 'GET_NODE_GROUP_LIST_SUCCESS';
export const GET_NODE_GROUP_LIST_FAIL = 'GET_NODE_GROUP_LIST_FAIL';

export const GET_NODE_GROUP_START = 'GET_NODE_GROUP_START';
export const GET_NODE_GROUP_SUCCESS = 'GET_NODE_GROUP_SUCCESS';
export const GET_NODE_GROUP_FAIL = 'GET_NODE_GROUP_FAIL';

export const CREATE_NODE_GROUP_START = 'CREATE_NODE_GROUP_START';
export const CREATE_NODE_GROUP_SUCCESS = 'CREATE_NODE_GROUP_SUCCESS';
export const CREATE_NODE_GROUP_FAIL = 'CREATE_NODE_GROUP_FAIL';

export const DELETE_NODE_GROUP_START = 'DELETE_NODE_GROUP_START';
export const DELETE_NODE_GROUP_SUCCESS = 'DELETE_NODE_GROUP_SUCCESS';
export const DELETE_NODE_GROUP_FAIL = 'DELETE_NODE_GROUP_FAIL';

export const GET_NODE_GROUP_OPTIONS_START = 'GET_NODE_GROUP_OPTIONS_START';
export const GET_NODE_GROUP_OPTIONS_SUCCESS = 'GET_NODE_GROUP_OPTIONS_SUCCESS';
export const GET_NODE_GROUP_OPTIONS_FAIL = 'GET_NODE_GROUP_OPTIONS_FAIL';