import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../store/actions';
import './Login.css';

const Login = ({ loading, onLogin }) => {
	document.title = 'Login - ' + process.env.REACT_APP_PAGE_TITLE;

	const [username, updateUserName] = useState('');
	const [password, updatePassword] = useState('');
	const [errors, updateErrors] = useState({});
	const [isTouched, updateTouched] = useState({});

	const validation = {
		username: ['required'],
		password: ['required']
	};

	const handleFieldChange = event => {
		const id = event.target.id;
		const value = event.target.value;
		const _errors = { ...errors };
		const _isTouched = {
			...isTouched,
			[id]: true
		};

		if(validation[id] && (value + '').length === 0) {
			_errors[id] = 'required';
		} else {
			delete _errors[id];
		}

		if(id === 'username') {
			updateUserName(value);
		} else if(id === 'password') {
			updatePassword(value);
		}
		updateErrors(_errors);
		updateTouched(_isTouched);
	};

	const handleSubmit = () => {
		const dataToSend = {
			username,
			password
		};
		onLogin(dataToSend);
	};

	const buttonDisabled = loading || !password.length || !username.length || errors.username || errors.password;

	return (
		<div className="container vpndb-login">
			<div className="row justify-content-center">
				<div className="col-xl-6 col-lg-8 col-md-10">
					<div className="card o-hidden border-0 shadow-lg my-5">
						<div className="card-body p-0">
							<div className="p-5">
								<div className="text-center">
									<h1 className="h4 text-gray-900 mb-4">Login</h1>
								</div>
								<div className="form-user">
									<div className="form-group">
										<input type="text"
											id="username"
											name="username"
											placeholder="Username"
											value={username}
											className={'form-control form-control-user ' + (isTouched.username && errors.username ? 'field-error' : '')}
											onChange={handleFieldChange} />
										{
											isTouched.username && errors.username ?
												<div className="form-control-errors-wrapper">
													{ errors.username === 'required' && <p>Username is required.</p> }
												</div> :
												null
										}
									</div>
									<div className="form-group">
										<input  type="password"
											id="password"
											name="password"
											placeholder="Password"
											value={password}
											className={'form-control form-control-user ' + (errors.password ? 'form-control-error' : '')}
											onChange={handleFieldChange} />
										{
											isTouched.password && errors.password ?
												<div className="form-control-errors-wrapper">
													{ errors.password === 'required' && <p>Password is required.</p> }
												</div> :
												null
										}
									</div>
									<button className={'btn btn-user btn-block btn-primary ' + (buttonDisabled ? '' : '')}
										disabled={buttonDisabled}
										onClick={handleSubmit}>Login</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.auth.user,
		loading: state.auth.loading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLogin: user => dispatch(login(user))
	};
};

Login.propTypes = {
	user: PropTypes.object,
	loading: PropTypes.bool,
	onLogin: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);