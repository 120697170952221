import {
	GET_PROVIDER_LIST_START,
	GET_PROVIDER_LIST_SUCCESS,
	GET_PROVIDER_LIST_FAIL,

	GET_PROVIDER_START,
	GET_PROVIDER_SUCCESS,
	GET_PROVIDER_FAIL,

	CREATE_PROVIDER_START,
	CREATE_PROVIDER_SUCCESS,
	CREATE_PROVIDER_FAIL,

	DELETE_PROVIDER_START,
	DELETE_PROVIDER_SUCCESS,
	DELETE_PROVIDER_FAIL
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	providerList: {},
	provider: {},
	loading: false,
	providerIsLoading: false,
	isCreating: false,
	isDeleting: false
};

/* provider list start */
const getProviderListStart = (state) => {
	return utils.updateObject(state, {
		loading: true
	});
};

const getProviderListSuccess = (state, action) => {
	return utils.updateObject(state, {
		providerList: action.providerList,
		loading: false
	});
};

const getProviderListFail = (state) => {
	return utils.updateObject(state, {
		providerList: {},
		loading: false
	});
};
/* provider list end */

/* provider start */
const getProviderStart = (state) => {
	return utils.updateObject(state, {
		providerIsLoading: true
	});
};

const getProviderSuccess = (state, action) => {
	return utils.updateObject(state, {
		provider: action.provider,
		providerIsLoading: false
	});
};

const getProviderFail = (state) => {
	return utils.updateObject(state, {
		provider: {},
		providerIsLoading: false
	});
};
/* provider end */

/* provider create/edit start */
const createProviderStart = (state) => {
	return utils.updateObject(state, {
		isCreating: true
	});
};

const createProviderSuccess = (state, action) => {
	return utils.updateObject(state, {
		provider: action.provider,
		isCreating: false
	});
};

const createProviderFail = (state) => {
	return utils.updateObject(state, {
		isCreating: false
	});
};
/* provider create/edit end */

/* provider delete start */
const deleteProviderStart = (state) => {
	return utils.updateObject(state, {
		isDeleting: true
	});
};

const deleteProviderSuccess = (state, action) => {
	return utils.updateObject(state, {
		providerList: action.providerList,
		isDeleting: false
	});
};

const deleteProviderFail = (state) => {
	return utils.updateObject(state, {
		isDeleting: false
	});
};
/* provider delete end */


const providerReducer = (state = initialState, action = {}) => {
	switch (action.type) {
	case GET_PROVIDER_LIST_START: return getProviderListStart(state, action);
	case GET_PROVIDER_LIST_SUCCESS: return getProviderListSuccess(state, action);
	case GET_PROVIDER_LIST_FAIL: return getProviderListFail(state, action);

	case GET_PROVIDER_START: return getProviderStart(state, action);
	case GET_PROVIDER_SUCCESS: return getProviderSuccess(state, action);
	case GET_PROVIDER_FAIL: return getProviderFail(state, action);

	case CREATE_PROVIDER_START: return createProviderStart(state, action);
	case CREATE_PROVIDER_SUCCESS: return createProviderSuccess(state, action);
	case CREATE_PROVIDER_FAIL: return createProviderFail(state, action);

	case DELETE_PROVIDER_START: return deleteProviderStart(state, action);
	case DELETE_PROVIDER_SUCCESS: return deleteProviderSuccess(state, action);
	case DELETE_PROVIDER_FAIL: return deleteProviderFail(state, action);

	default:
		return state;
	}
};

export default providerReducer;