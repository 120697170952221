import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { getEntityList, deleteEntityById } from '../../store/actions';
import utils from '../../services/utils';

import Loader from '../../components/UI/Loader/Loader';
import ConfirmModal from '../../components/UI/ConfirmModal/ConfirmModal';
import './EntityList.css';

const PER_PAGE = process.env.REACT_APP_PAGE_SIZE;

class EntityList extends Component {
	state = {
		deletingEntity: {},
		search: '',
		urlParams: '',
		currentCursor: null,
		activePage: 1
	};

	componentDidMount() {
		document.title = 'Entity - ' + process.env.REACT_APP_PAGE_TITLE;
		const cursor = (this.props.location && this.props.location.state && this.props.location.state.currentCursor) || null;
		this.setState({currentCursor: cursor}, () => {
			this.props.onGetEntityList(null, (cursor || 'initial'), PER_PAGE);
		});
		document.addEventListener('keydown', this.handleKeyPress, false);
	}

	componentWillUnmount(){
		document.removeEventListener('keydown', this.handleKeyPress, false);
	}

	handleKeyPress = e => {
		const {search} = this.state;

		if(search) {
			if(e.keyCode === 27) {
				this.setState({search: ''}, () => {
					this.handleSearchBy();
				});
			} else if(e.keyCode === 13) {
				this.handleSearchBy();
			}
		}
	};

	handlePageChange = page => {
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const pageParams = utils.processPageChange(currentCursor, urlParams, page);

		this.setState(pageParams, () => {
			this.props.onGetEntityList(pageParams.urlParams, pageParams.currentCursor, PER_PAGE);
		});
	};

	handleOrderBy = sortBy => {
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const orderByParams = utils.processOrderBy(currentCursor, urlParams, sortBy);

		this.setState(orderByParams, () => {
			this.props.onGetEntityList(orderByParams.urlParams, orderByParams.currentCursor, PER_PAGE);
		});
	};

	handleSearchBy = () => {
		const {search} = this.state;
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const searchByParams = utils.processSearchBy(currentCursor, urlParams, search);

		this.setState({ ...searchByParams, activePage: 1 }, () => {
			this.props.onGetEntityList(searchByParams.urlParams, searchByParams.currentCursor, PER_PAGE);
		});
	};

	handleSearchChange = event => {
		const search = event.target.value;
		this.setState({search}, () => {
			if(!search) {
				this.handleSearchBy();
			}
		});
	};

	handleDeleteEntityById = entity => {
		this.setState({deletingEntity: entity});
	};

	handleCloseModal = () => {
		this.setState({deletingEntity: {}});
	};

	handleConfirmModal = () => {
		const { deletingEntity } = this.state;
		this.props.onDeleteEntityById(deletingEntity.enid).then(() => {
			this.setState({deletingEntity: {}});
		});
	};

	render() {
		const { entityList, loading, isEntityDeleting } = this.props;
		const { deletingEntity, search, urlParams, activePage } = this.state;
		const pageCount = entityList.count || 1;
		let _entityList = (entityList && entityList.results) ? [ ...entityList.results ] : [];

		return (
			<div className="container-fluid">
				<div className="card shadow">
					<div className="card-header py-3">
						<h1 className="h3 text-gray-800">Entity</h1>
						<NavLink exact to="/entity/new" className="float-right">Create</NavLink>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-sm-12 col-md-6">
								<div className="input-group">
									<input type="text"
										value={search}
										className="form-control bg-light border-0 small"
										placeholder="Search for..."
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={this.handleSearchChange}/>
									<div className="input-group-append">
										<button className="btn btn-primary" type="button" onClick={this.handleSearchBy}>
											<i className="fas fa-search fa-sm"></i>
										</button>
									</div>
								</div>
								<p className="search-field-info">Search performed through all pages within all text fields.</p>
							</div>
							<div className="col-sm-12 col-md-6"></div>
						</div>

						<div className="row">
							{loading ?
								<Loader /> :
								<div className="col-sm-12 dataTables_container">
									<div className="dataTables_wrapper">
										<table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0" style={{width: '100%'}}>
											{_entityList.length ?
												<Fragment>
													<thead>
														<tr role="row">
															<th className={`sorting${(urlParams && urlParams.indexOf('name') > -1) ? (urlParams.indexOf('-name') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('name')} style={{width: '33%'}}>Name</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('client_domain') > -1) ? (urlParams.indexOf('-client_domain') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('client_domain')} style={{width: '33%'}}>Client Domain</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('mgt_domain') > -1) ? (urlParams.indexOf('-mgt_domain') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('mgt_domain')} style={{width: '33%'}}>Mgt Domain</th>
															<th></th>
														</tr>
													</thead>
													<tfoot>
														<tr>
															<th>Name</th>
															<th>Client Domain</th>
															<th>Mgt Domain</th>
															<th></th>
														</tr>
													</tfoot>
												</Fragment> : null
											}
											<tbody>
												{_entityList.map(item => (
													<tr role="row" className="odd" key={item.enid}>
														<td className="sorting_1"><NavLink exact to={`/entity/${item.enid}`}>{item.name || 'N/A'}</NavLink></td>
														<td>{item.client_domain || 'N/A'}</td>
														<td>{item.mgt_domain || 'N/A'}</td>
														<td>
															<div className="action-wrapper">
																<NavLink exact to={`/entity/${item.enid}`} className="float-right"><i className="fas fa-edit"></i></NavLink>
																<i className="fas fa-trash" onClick={() => this.handleDeleteEntityById(item)}></i>
															</div>
														</td>
													</tr>
												))}
												{_entityList.length === 0 ?
													<tr role="row" className="odd">
														<td colSpan="4">
															<div className="empty-list">List is empty</div>
														</td>
													</tr> : null}
											</tbody>
										</table>
									</div>
								</div>
							}
						</div>

						{loading || _entityList.length === 0 ?
							null :
							<div className="row">
								<div className="col-sm-12 col-md-5"></div>
								<div className="col-sm-12 col-md-7">
									<div className="dataTables_paginate paging_simple_numbers">
										<Pagination
											innerClass="pagination float-right"
											itemClass="page-item"
											linkClass="page-link"
											activePage={activePage}
											itemsCountPerPage={PER_PAGE}
											totalItemsCount={pageCount}
											pageRangeDisplayed={5}
											onChange={this.handlePageChange}
										/>
									</div>
								</div>
							</div>
						}
					</div>
				</div>

				<ConfirmModal
					title="Delete Entity"
					content={'Are you sure, you want to delete \'' + deletingEntity.name + '\'' }
					showModal={deletingEntity.enid || deletingEntity.enid === 0}
					disabled={isEntityDeleting}
					onCancel={this.handleCloseModal}
					onConfirm={this.handleConfirmModal}/>
			</div>
		);
	}

}

const mapStateToProps = state => {
	return {
		entityList: state.entity.entityList,
		loading: state.entity.loading,
		isEntityDeleting: state.entity.isDeleting
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetEntityList: (urlParams, cursor, perPage) => dispatch(getEntityList(urlParams, cursor, perPage)),
		onDeleteEntityById: id => dispatch(deleteEntityById(id))
	};
};

EntityList.propTypes = {
	location: PropTypes.object,
	entityList: PropTypes.object,
	loading: PropTypes.bool,
	isEntityDeleting: PropTypes.bool,
	onGetEntityList: PropTypes.func,
	onDeleteEntityById: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityList);