import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { getProviderList, deleteProviderById } from '../../store/actions';
import moment from 'moment';
import utils from '../../services/utils';

import Loader from '../../components/UI/Loader/Loader';
import ConfirmModal from '../../components/UI/ConfirmModal/ConfirmModal';
import './ProviderList.css';

const PER_PAGE = process.env.REACT_APP_PAGE_SIZE;

class ProviderList extends Component {
	state = {
		deletingProvider: {},
		search: '',
		urlParams: '',
		currentCursor: null,
		activePage: 1
	};

	componentDidMount() {
		document.title = 'Provider - ' + process.env.REACT_APP_PAGE_TITLE;
		const cursor = (this.props.location && this.props.location.state && this.props.location.state.currentCursor) || null;
		this.setState({currentCursor: cursor}, () => {
			this.props.onGetProviderList(null, (cursor || 'initial'), PER_PAGE);
		});
		document.addEventListener('keydown', this.handleKeyPress, false);
	}

	componentWillUnmount(){
		document.removeEventListener('keydown', this.handleKeyPress, false);
	}

	handleKeyPress = e => {
		const {search} = this.state;

		if(search) {
			if(e.keyCode === 27) {
				this.setState({search: ''}, () => {
					this.handleSearchBy();
				});
			} else if(e.keyCode === 13) {
				this.handleSearchBy();
			}
		}
	};

	handlePageChange = page => {
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const pageParams = utils.processPageChange(currentCursor, urlParams, page);

		this.setState(pageParams, () => {
			this.props.onGetProviderList(pageParams.urlParams, pageParams.currentCursor, PER_PAGE);
		});
	};

	handleOrderBy = sortBy => {
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const orderByParams = utils.processOrderBy(currentCursor, urlParams, sortBy);

		this.setState(orderByParams, () => {
			this.props.onGetProviderList(orderByParams.urlParams, orderByParams.currentCursor, PER_PAGE);
		});
	};

	handleSearchBy = () => {
		const {search} = this.state;
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const searchByParams = utils.processSearchBy(currentCursor, urlParams, search);

		this.setState({ ...searchByParams, activePage: 1 }, () => {
			this.props.onGetProviderList(searchByParams.urlParams, searchByParams.currentCursor, PER_PAGE);
		});
	};

	handleSearchChange = event => {
		const search = event.target.value;
		this.setState({search}, () => {
			if(!search) {
				this.handleSearchBy();
			}
		});
	};

	handleDeleteProviderById = provider => {
		this.setState({deletingProvider: provider});
	};

	handleCloseModal = () => {
		this.setState({deletingProvider: {}});
	};

	handleConfirmModal = () => {
		const { deletingProvider } = this.state;
		this.props.onDeleteProviderById(deletingProvider.pvid).then(() => {
			this.setState({deletingProvider: {}});
		});
	};

	render() {
		const { providerList, loading, isProviderDeleting } = this.props;
		const { deletingProvider, search, urlParams, activePage } = this.state;
		const pageCount = providerList.count || 1;
		let _providerList = (providerList && providerList.results) ? [ ...providerList.results ] : [];

		return (
			<div className="container-fluid">
				<div className="card shadow">
					<div className="card-header py-3">
						<h1 className="h3 text-gray-800">Provider</h1>
						<NavLink exact to="/provider/new" className="float-right">Create</NavLink>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-sm-12 col-md-6">
								<div className="input-group">
									<input type="text"
										value={search}
										className="form-control bg-light border-0 small"
										placeholder="Search for..."
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={this.handleSearchChange}/>
									<div className="input-group-append">
										<button className="btn btn-primary" type="button" onClick={this.handleSearchBy}>
											<i className="fas fa-search fa-sm"></i>
										</button>
									</div>
								</div>
								<p className="search-field-info">Search performed through all pages within all text fields.</p>
							</div>
							<div className="col-sm-12 col-md-6"></div>
						</div>

						<div className="row">
							{loading ?
								<Loader /> :
								<div className="col-sm-12 dataTables_container">
									<div className="dataTables_wrapper">
										<table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0" style={{width: '100%'}}>
											{_providerList.length ?
												<Fragment>
													<thead>
														<tr role="row">
															<th className={`sorting${(urlParams && urlParams.indexOf('name') > -1) ? (urlParams.indexOf('-name') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('name')} style={{width: '300px'}}>Name</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('url') > -1 && urlParams.indexOf('tos_url') === -1) ? (urlParams.indexOf('-url') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('url')} style={{width: '200px'}}>Url</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('tos_url') > -1) ? (urlParams.indexOf('-tos_url') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('tos_url')} style={{width: '100px'}}>tos_url</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('portal_addr') > -1) ? (urlParams.indexOf('-portal_addr') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('portal_addr')} style={{width: '90px'}}>portal_addr</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('portal_user') > -1) ? (urlParams.indexOf('-portal_user') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('portal_user')} style={{width: '90px'}}>portal_user</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('portal_pass') > -1) ? (urlParams.indexOf('-portal_pass') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('portal_pass')} style={{width: '90px'}}>portal_pass</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('notes') > -1) ? (urlParams.indexOf('-notes') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('notes')} style={{width: '90px'}}>Notes</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('created') > -1) ? (urlParams.indexOf('-created') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('created')} style={{minWidth: '80px'}}>Created</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('modified') > -1) ? (urlParams.indexOf('-modified') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('modified')} style={{minWidth: '80px'}}>Modified</th>
															<th></th>
														</tr>
													</thead>
													<tfoot>
														<tr>
															<th>Name</th>
															<th>Url</th>
															<th>tos_url</th>
															<th>portal_addr</th>
															<th>portal_user</th>
															<th>portal_pass</th>
															<th>Notes</th>
															<th>Created</th>
															<th>Modified</th>
															<th></th>
														</tr>
													</tfoot>
												</Fragment> : null
											}
											<tbody>
												{_providerList.map(item => (
													<tr key={item.pvid} role="row" className="odd">
														<td className="sorting_1"><NavLink exact to={`/provider/${item.pvid}`}>{item.name || 'N/A'}</NavLink></td>
														<td>{item.url || 'N/A'}</td>
														<td>{item.tos_url || 'N/A'}</td>
														<td>{item.portal_addr || 'N/A'}</td>
														<td>{item.portal_user || 'N/A'}</td>
														<td>{item.portal_pass || 'N/A'}</td>
														<td>{item.notes || 'N/A'}</td>
														<td>{item.created ? moment(1000*item.created).format('YYYY-MM-DD') : 'N/A'}</td>
														<td>{item.modified ? moment(1000*item.modified).format('YYYY-MM-DD') : 'N/A'}</td>
														<td>
															<div className="action-wrapper">
																<NavLink exact to={`/provider/${item.pvid}`} className="float-right"><i className="fas fa-edit"></i></NavLink>
																<i className="fas fa-trash" onClick={() => this.handleDeleteProviderById(item)}></i>
															</div>
														</td>
													</tr>
												))}
												{_providerList.length === 0 ?
													<tr role="row" className="odd">
														<td colSpan="15">
															<div className="empty-list">List is empty</div>
														</td>
													</tr> : null}
											</tbody>
										</table>
									</div>
								</div>
							}
						</div>

						{loading || _providerList.length === 0 ?
							null :
							<div className="row">
								<div className="col-sm-12 col-md-5"></div>
								<div className="col-sm-12 col-md-7">
									<div className="dataTables_paginate paging_simple_numbers">
										<Pagination
											innerClass="pagination float-right"
											itemClass="page-item"
											linkClass="page-link"
											activePage={activePage}
											itemsCountPerPage={PER_PAGE}
											totalItemsCount={pageCount}
											pageRangeDisplayed={5}
											onChange={this.handlePageChange}
										/>
									</div>
								</div>
							</div>
						}
					</div>
				</div>

				<ConfirmModal
					title="Delete Provider"
					content={'Are you sure, you want to delete \'' + deletingProvider.name + '\'' }
					showModal={deletingProvider.pvid || deletingProvider.pvid === 0}
					disabled={isProviderDeleting}
					onCancel={this.handleCloseModal}
					onConfirm={this.handleConfirmModal}/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		providerList: state.provider.providerList,
		loading: state.provider.loading,
		isProviderDeleting: state.provider.isDeleting
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetProviderList: (urlParams, cursor, perPage) => dispatch(getProviderList(urlParams, cursor, perPage)),
		onDeleteProviderById: id => dispatch(deleteProviderById(id))
	};
};

ProviderList.propTypes = {
	location: PropTypes.object,
	providerList: PropTypes.object,
	loading: PropTypes.bool,
	isProviderDeleting: PropTypes.bool,
	onGetProviderList: PropTypes.func,
	onDeleteProviderById: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderList);