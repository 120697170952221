import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getProviderById, createProvider } from '../../store/actions';
import moment from 'moment';

import Loader from '../../components/UI/Loader/Loader';
import './Provider.css';

const validation = {
	name: ['required'],
	parent_pvid: ['required', 'number']
};

const INITIALSTATE = {
	pvid: null,
	name: '',
	parent_pvid: '',
	url: '',
	tos_url: '',
	portal_addr: '',
	portal_user: '',
	portal_pass: '',
	notes: '',
	modified: 0,
	created: 0,

	errors: {},
	isTouched: {}
};

class Provider extends Component {
	state = {...INITIALSTATE};

	componentDidMount() {
		document.title = 'Provider - ' + process.env.REACT_APP_PAGE_TITLE;
		const { location: { pathname } } = this.props;
		const pvid = pathname.split('/provider/')[1];
		if(pvid !== 'new') {
			this.props.onGetProviderById(pvid);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { location: { pathname } } = this.props;
		const pvid = pathname.split('/provider/')[1];
		if(!nextProps.isCreating && nextProps.provider && nextProps.provider.pvid && (pvid !== 'new')) {
			const data = nextProps.provider;
			document.title = (data && (data.name + ' - ' + process.env.REACT_APP_PAGE_TITLE)) || ('Provider - ' + process.env.REACT_APP_PAGE_TITLE);
			this.setState({
				pvid: (data && data.pvid) || null,
				name: (data && data.name) || '',
				parent_pvid: (data && data.parent_pvid) || '',
				url: (data && data.url) || '',
				tos_url: (data && data.tos_url) || '',
				portal_addr: (data && data.portal_addr) || '',
				portal_user: (data && data.portal_user) || '',
				portal_pass: (data && data.portal_pass) || '',
				notes: (data && data.notes) || '',
				modified: (data && data.modified) || 0,
				created: (data && data.created) || 0
			});
		}
	}

	handleFieldChange = event => {
		const { isTouched, errors } = this.state;
		const id = event.target.id;
		const value = event.target.value;
		const _errors = { ...errors };
		const _isTouched = {
			...isTouched,
			[id]: true
		};

		if(validation[id]) {
			if(validation[id].indexOf('required') > -1 && (value + '').length === 0) {
				_errors[id] = 'required';
			} else if(validation[id].indexOf('number') > -1 && value.length && !(parseInt(value) > -1)) {
				_errors[id] = 'number';
			} else {
				delete _errors[id];
			}
		}

		this.setState({
			[id]: value,
			isTouched: _isTouched,
			errors: _errors
		});
	};

	handleCreateProvider = (e, redirect, openNewBlank, duplicate) => {
		const { name, parent_pvid, url, tos_url, portal_addr, portal_user, portal_pass, notes } = this.state;
		const { history, location: { pathname } } = this.props;
		const pvid = pathname.split('/provider/')[1];
		if(name && parent_pvid) {
			const dataToSend = {
				name,
				parent_pvid
			};
			dataToSend.url = (url.length === 0) ? null : url;
			dataToSend.tos_url = (tos_url.length === 0) ? null : tos_url;
			dataToSend.portal_addr = (portal_addr.length === 0) ? null : portal_addr;
			dataToSend.portal_user = (portal_user.length === 0) ? null : portal_user;
			dataToSend.portal_pass = (portal_pass.length === 0) ? null : portal_pass;
			dataToSend.notes = (notes.length === 0) ? null : notes;

			this.props.onCreateProvider(dataToSend, (pvid === 'new' ? null : pvid)).then(response => {
				if(response.status && response.status !== 200 && response.status !== 201) {
					return;
				}
				if(redirect) {
					history.push('/provider');
				}
				if(openNewBlank) {
					history.push('/provider/new');
					this.setState(duplicate ? {pvid: null} : {...INITIALSTATE});
				}
			});
		}
	};

	render() {
		const { isLoading, isCreating, history } = this.props;
		const {
			pvid,
			name,
			parent_pvid,
			url,
			tos_url,
			portal_addr,
			portal_user,
			portal_pass,
			notes,
			modified,
			created,

			errors,
			isTouched
		} = this.state;
		const editMode = pvid || pvid === 0;
		const buttonDisabled = isCreating || errors.name || !name.length || errors.parent_pvid || !(parent_pvid + '').length;

		return (
			<Fragment>
				{isLoading ?
					<div className="loader-wrapper">
						<Loader />
					</div> :
					<div className="content-wrapper">
						<div className="container-fluid fields-wrapper">
							<h1 className="h3 mb-2 text-gray-800">{isLoading ? '' : (name || 'Create New Provider')}</h1>
							{editMode && created ? <p className="mb-0 text-gray-800">Created: {moment(1000*created).format('YYYY-MM-DD HH:mm')}</p> : null}
							{editMode && modified ? <p className="mb-4 text-gray-800">Modified: {moment(1000*modified).format('YYYY-MM-DD HH:mm')}</p> : null}

							<div className="row">
								<div className="col-sm-12 col-md-6">
									<div className="form-group">
										<label>Name<span>*</span></label>
										<input type="text"
											className={'form-control ' + (isTouched.name && errors.name ? 'field-error' : '')}
											id="name"
											value={name}
											onChange={this.handleFieldChange} />
										{
											isTouched.name && errors.name ?
												<div className="form-control-errors-wrapper">
													{ errors.name === 'required' && <p>Name is required.</p> }
												</div> :
												null
										}
									</div>
									<div className="form-group">
										<label>Parent Pvid<span>*</span></label>
										<input type="text"
											className={'form-control ' + (isTouched.parent_pvid && errors.parent_pvid ? 'field-error' : '')}
											id="parent_pvid"
											value={parent_pvid}
											onChange={this.handleFieldChange} />
										{
											isTouched.parent_pvid && errors.parent_pvid ?
												<div className="form-control-errors-wrapper">
													{ errors.parent_pvid === 'required' && <p>Parent Pvid is required.</p> }
													{ errors.parent_pvid === 'number' && <p>A valid integer is required.</p> }
												</div> :
												null
										}
									</div>
									<div className="form-group">
										<label>Url</label>
										<input type="text"
											className="form-control"
											id="url"
											value={url}
											onChange={this.handleFieldChange} />
									</div>
									<div className="form-group">
										<label>Tos Url</label>
										<input type="text"
											className="form-control"
											id="tos_url"
											value={tos_url}
											onChange={this.handleFieldChange} />
									</div>
								</div>

								<div className="col-sm-12 col-md-6">
									<div className="form-group">
										<label>Portal Addr</label>
										<input type="text"
											className="form-control"
											id="portal_addr"
											value={portal_addr}
											onChange={this.handleFieldChange} />
									</div>
									<div className="form-group">
										<label>Portal User</label>
										<input type="text"
											className="form-control"
											id="portal_user"
											value={portal_user}
											onChange={this.handleFieldChange} />
									</div>
									<div className="form-group">
										<label>Portal Pass</label>
										<input type="text"
											className="form-control"
											id="portal_pass"
											value={portal_pass}
											onChange={this.handleFieldChange} />
									</div>
									<div className="form-group">
										<label>Notes</label>
										<input type="text"
											className="form-control"
											id="notes"
											value={notes}
											onChange={this.handleFieldChange} />
									</div>
								</div>
							</div>
						</div>
						<div className="container-fluid">
							<div className="row">
								<div className="col-lg-12">
									<button type="submit"
										className="btn btn-primary mb-2 float-left"
										onClick={() => history.push('/provider')}>Back</button>
									<button type="submit"
										className="btn btn-primary mb-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateProvider(e, true)}>Save</button>
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={this.handleCreateProvider}>{editMode ? 'Save and continue editing' : 'Save and duplicate new'}</button>
									{editMode ?
										<button type="submit"
											className="btn btn-primary mb-2 mr-2 float-right"
											disabled={buttonDisabled}
											onClick={e => this.handleCreateProvider(e, false, true, true)}>Save and duplicate new</button> : null
									}
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateProvider(e, false, true)}>Save and add another</button>
								</div>
							</div>
						</div>
					</div>
				}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		provider: state.provider.provider,
		isCreating: state.provider.isCreating,
		isLoading: state.provider.providerIsLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetProviderById: id => dispatch(getProviderById(id)),
		onCreateProvider: (providerData, id) => dispatch(createProvider(providerData, id))
	};
};

Provider.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	provider: PropTypes.object,
	isCreating: PropTypes.bool,
	isLoading: PropTypes.bool,
	onGetProviderById: PropTypes.func,
	onCreateProvider: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Provider);