import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getNodeById, createNode, getAssetList, getEntityList, getSubnetList, getNodeOptions } from '../../store/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import config from '../../services/config';

import Loader from '../../components/UI/Loader/Loader';
import CustomSelect from '../../components/UI/CustomSelect/CustomSelect';
import CustomInput from '../../components/UI/CustomInput/CustomInput';
import CustomTextArea from '../../components/UI/CustomTextArea/CustomTextArea';
import './Node.css';

const INITIALSTATE = {
	nodeDetails: {
		ndid: null
	},

	errors: {},
	isTouched: {}
};

class Node extends Component {
	state = {...INITIALSTATE};

	componentDidMount() {
		document.title = 'Node - ' + process.env.REACT_APP_PAGE_TITLE;
		const { location: { pathname } } = this.props;
		const ndid = pathname.split('/node/')[1];
		Promise.all([this.props.onGetAssetList(), this.props.onGetEntityList(), this.props.onGetSubnetList()])
			.then(() => {
				if(ndid === 'new') {
					this.props.onGetNodeOptions(null);
				} else {
					this.props.onGetNodeById(ndid);
					this.props.onGetNodeOptions(ndid);
				}
			});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { location: { pathname } } = this.props;
		const ndid = pathname.split('/node/')[1];
		if(!nextProps.isCreating && nextProps.node && nextProps.node.ndid && (ndid !== 'new')) {
			const nodeDetails = nextProps.node;
			document.title = (nodeDetails && (nodeDetails.hostname + ' - ' + process.env.REACT_APP_PAGE_TITLE)) || ('Node - ' + process.env.REACT_APP_PAGE_TITLE);
			this.setState({nodeDetails: {...nodeDetails}});
		}
	}

	renderFields = fields => {
		const { assetList, entityList, subnetList } = this.props;
		const { errors, isTouched } = this.state;
		return fields.filter(f => config.NODE_READONLY_FIELDS.indexOf(f.id) === -1).map(f => {
			if(f.id === 'ndid' || f.id === 'created' || f.id === 'modified') {
				return null;
			}
			if(f.id === 'asid' || f.id === 'enid' || f.id === 'snid') {f.type = 'choice';}

			switch(f.type) {
			case 'choice':
				const optionsList = (f.id === 'asid') ? ((assetList && assetList.results) || []).map(a => ({value: a.asset.asid, display_name: a.asset.hostname})) :
					(f.id === 'enid') ? ((entityList && entityList.results) || []).map(e => ({value: e.enid, display_name: e.name})) :
						(f.id === 'snid') ? ((subnetList && subnetList.results) || []).map(s => ({value: s.snid, display_name: s.cidr})) : f.choices;
				return (
					<div className="col-sm-12 col-md-4 col-lg-3" key={f.id}>
						<CustomSelect
							id={f.id}
							label={f.label}
							value={('' + this.state.nodeDetails[f.id]) || -1}
							type={f.type}
							disabled={f.read_only}
							required={f.required}
							isTouched={isTouched}
							errors={errors}
							optionList={optionsList}
							onChange={this.handleFieldChange} />
					</div>
				);
			case 'field':
			case 'string':
			case 'float':
			case 'integer':
			case 'decimal':
				return f.id === 'notes' ?
					(
						<div className="col-md-12 col-lg-9" key={f.id}>
							<CustomTextArea
								id={f.id}
								label={f.label}
								value={this.state.nodeDetails[f.id] || ''}
								type={f.type}
								disabled={f.read_only}
								max_length={(f.id === 'iata' ? 3 : f.max_length)}
								min_length={f.min_length}
								error_message={this.state.errors[f.id] || ''}
								required={f.required}
								isTouched={isTouched}
								errors={errors}
								onChange={this.handleFieldChange} />
						</div>
					) :
					(
						<div className="col-sm-12 col-md-4 col-lg-3" key={f.id}>
							<CustomInput
								id={f.id}
								label={f.label}
								value={(this.state.nodeDetails[f.id] || this.state.nodeDetails[f.id] === 0) ? this.state.nodeDetails[f.id] : ''}
								type={f.type}
								disabled={f.read_only}
								max_length={(f.id === 'iata' ? 3 : f.max_length)}
								min_length={f.min_length}
								error_message={this.state.errors[f.id] || ''}
								required={f.required}
								isTouched={isTouched}
								errors={errors}
								onChange={this.handleFieldChange} />
						</div>
					);
			case 'date':
				return (
					<div className="col-sm-12 col-md-4 col-lg-3" key={f.id}>
						<div className="form-group">
							<label>{f.label}</label>
							<DatePicker
								id={f.id}
								className="form-control"
								dateFormat="yyyy-MM-dd"
								selected={f.id ? new Date(f.id) : ''}
								onChangeRaw={e => e.preventDefault()}
								onChange={ date => this.handleDateChange(f.id, date)}
							/>
						</div>
					</div>
				);
			default:
				return null;
			}
		});
	};

	handleFieldChange = (event, type) => {
		const { nodeDetails, isTouched, errors } = this.state;
		const { nodeOptions } = this.props;
		const id = event.target.id;
		let value = event.target.value || '';
		const _errors = { ...errors };
		const _isTouched = {
			...isTouched,
			[id]: true
		};

		if(type === 'choice' && (+value === -1 || value === '')) {
			value = null;
		}

		if((type === 'integer' || type === 'float' || type === 'decimal') && !/^[0-9,.]*$/.test(value)) {
			return;
		}

		const _n = nodeOptions || {};
		if(_n[id]) {
			if(_n[id]['required'] && (!value || (value + '').length === 0 || (+value === -1))) {
				_errors[id] = 'required';
			} else if(_n[id]['max_length'] && value.length && value.length > (id === 'iata' ? 3 : _n[id]['max_length'])) {
				_errors[id] = 'max_length';
			}  else if(_n[id]['min_length'] && value.length && value.length < _n[id]['min_length']) {
				_errors[id] = 'min_length';
			} else {
				delete _errors[id];
			}
		}

		const _stateToUpdate = {
			isTouched: _isTouched,
			errors: _errors
		};

		_stateToUpdate.nodeDetails = {...nodeDetails, [id]: value};

		this.setState(_stateToUpdate);
	};

	handleDateChange = (id, date) => {
		const { nodeDetails } = this.state;

		this.setState({
			nodeDetails: {...nodeDetails, [id]: moment(date).format('YYYY-MM-DD')}
		});
	};

	handleCreateNode = (e, redirect, openNewBlank, duplicate) => {
		const { nodeDetails, errors } = this.state;
		const { history, location: { pathname } } = this.props;
		const ndid = pathname.split('/node/')[1];
		if(Object.keys(errors).length === 0) {
			const dataToSend = { ...nodeDetails };

			this.props.onCreateNode(dataToSend, (ndid === 'new' ? null : ndid)).then(response => {
				if(response.status && response.status !== 200 && response.status !== 201) {
					return;
				}
				if(redirect) {
					history.push('/node');
				}
				if(openNewBlank) {
					history.push('/node/new');
					this.setState(duplicate ? {nodeDetails: {...nodeDetails, ndid: null}} : {...INITIALSTATE});
				}
			});
		}
	};

	isRequiredFieldsEmpty = (fieldsArray, fields) => {
		let disabled = false;
		for(let i=0; i<fieldsArray.length; i++) {
			const fieldValue = fields[fieldsArray[i].id];
			if((fieldsArray[i].id !== 'ndid') && (typeof fieldValue === 'undefined' || ('' + fieldValue).length === 0 || fieldValue === -1) && fieldsArray[i].required) {
				disabled = true;
			}
		}
		return disabled;
	};

	groupFields = (fieldsArray, pattern) => {
		const _groupedFields = [];
		const _fieldsArray = fieldsArray.map(f => ({...f}));

		for(let i=0; i<pattern.length; i++) {
			_groupedFields.push({...pattern[i]});
			_groupedFields[i].fields = [...pattern[i].fields];

			for(let j=0; j<_groupedFields[i].fields.length; j++) {
				for(let k=0; k<_fieldsArray.length; k++) {
					if(pattern[i].fields[j] === _fieldsArray[k].id) {
						_groupedFields[i].fields[j] = _fieldsArray[k];
						_fieldsArray.splice(k, 1);
						k--;
					}
				}
			}
		}

		_groupedFields.push({id: 'additional', title: 'Additional fields', fields: [..._fieldsArray.filter(f => (f.id !== 'asid' && f.id !== 'created' && f.id !== 'modified'))]});
		return _groupedFields;
	};

	render() {
		const {  nodeOptions, isLoading, isCreating, history } = this.props;
		const {
			nodeDetails: {
				ndid,
				hostname,
				created,
				modified
			},

			errors
		} = this.state;

		const fieldsObj = {...nodeOptions};
		const fieldsArray = Object.keys(fieldsObj).map((f) => {
			return {
				...fieldsObj[f],
				id: f
			};
		});
		const groupedFields = this.groupFields(fieldsArray.sort((a) => (a.required ? -1 : 1)), config.NODE_FIELDS_GROUPING_PATTERN);

		const editMode = ndid || ndid === 0;
		const buttonDisabled = isCreating || Object.keys(errors).length || this.isRequiredFieldsEmpty(fieldsArray, this.state.nodeDetails);

		return (
			<Fragment>
				{isLoading ?
					<div className="loader-wrapper">
						<Loader />
					</div> :
					<div className="content-wrapper">
						<div className="container-fluid fields-wrapper">
							<h1 className="h3 mb-2 text-gray-800">{isLoading ? '' : (hostname || 'Create New Node')}</h1>

							<div className="row mb-4 readonly-fields-container">
								{fieldsArray.filter(f => config.NODE_READONLY_FIELDS.indexOf(f.id) !== -1 ).map(f => {
									return this.state.nodeDetails[f.id] ? <p key={f.id} className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">{f.label}: </span>{this.state.nodeDetails[f.id]}</p> :
										this.state.nodeDetails[f.id] ? <p key={f.id} className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">{f.label}: </span>{this.state.nodeDetails[f.id]}</p> : null;
								})}
								{editMode && created ? <p className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">Created: </span>{moment(1000*created).format('YYYY-MM-DD HH:mm')}</p> : null}
								{editMode && modified ? <p className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">Modified: </span>{moment(1000*modified).format('YYYY-MM-DD HH:mm')}</p> : null}
							</div>
							{groupedFields.map(g => {
								return g.fields.length ? (
									<div className="row group-fields" key={g.id}>
										{g.title ? <h4 className="col-lg-12 mb-0">{g.title}</h4> : null}
										{this.renderFields(g.fields)}
									</div>
								) : null;
							})}
						</div>

						<div className="container-fluid">
							<div className="row">
								<div className="col-lg-12">
									<button type="submit"
										className="btn btn-primary mb-2 float-left"
										onClick={() => history.push('/node')}>Back</button>
									<button type="submit"
										className="btn btn-primary mb-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateNode(e, true)}>Save</button>
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={this.handleCreateNode}>{editMode ? 'Save and continue editing' : 'Save and duplicate new'}</button>
									{editMode ?
										<button type="submit"
											className="btn btn-primary mb-2 mr-2 float-right"
											disabled={buttonDisabled}
											onClick={e => this.handleCreateNode(e, false, true, true)}>Save and duplicate new</button> : null
									}
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateNode(e, false, true)}>Save and add another</button>
								</div>
							</div>
						</div>
					</div>
				}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		assetList: state.asset.assetList,
		entityList: state.entity.entityList,
		subnetList: state.subnet.subnetList,
		node: state.node.node,
		nodeOptions: state.node.nodeOptions,
		isLoading: state.node.nodeIsLoading || state.node.nodeOptionsIsLoading || state.asset.loading || state.entity.loading || state.subnet.loading,
		isCreating: state.node.isCreating
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetNodeById: id => dispatch(getNodeById(id)),
		onGetNodeOptions: id => dispatch(getNodeOptions(id)),
		onGetAssetList: () => dispatch(getAssetList()),
		onGetEntityList: () => dispatch(getEntityList()),
		onGetSubnetList: () => dispatch(getSubnetList()),
		onCreateNode: (nodeData, ID) => dispatch(createNode(nodeData, ID))
	};
};

Node.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	assetList: PropTypes.object,
	entityList: PropTypes.object,
	subnetList: PropTypes.object,
	nodeOptions: PropTypes.object,
	node: PropTypes.object,
	isLoading: PropTypes.bool,
	isCreating: PropTypes.bool,
	onGetNodeById: PropTypes.func,
	onGetAssetList: PropTypes.func,
	onGetEntityList: PropTypes.func,
	onGetSubnetList: PropTypes.func,
	onCreateNode: PropTypes.func,
	onGetNodeOptions: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Node);
