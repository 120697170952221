import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import './Navigation.css';

const navigation = () => {
	return (
		<Fragment>
			<ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
				<NavLink className="sidebar-brand d-flex align-items-center justify-content-center" strict to="/subnet">
					<div className="sidebar-brand-text mx-3">VPNdb</div>
				</NavLink>
				<hr className="sidebar-divider my-0" />
				<li className="nav-item sidebar-nav"><NavLink strict to="/subnet" className="nav-link">Subnet</NavLink></li>
				<li className="nav-item sidebar-nav"><NavLink strict to="/entity" className="nav-link">Entity</NavLink></li>
				<li className="nav-item sidebar-nav"><NavLink strict to="/asset" className="nav-link">Asset</NavLink></li>
				<li className="nav-item sidebar-nav"><NavLink strict to="/node" className="nav-link">Node</NavLink></li>
				<li className="nav-item sidebar-nav"><NavLink strict to="/nodegroup" className="nav-link">Node Group</NavLink></li>
				<li className="nav-item sidebar-nav"><NavLink strict to="/provider" className="nav-link">Provider</NavLink></li>
				<hr className="sidebar-divider d-none d-md-block" />
			</ul>
		</Fragment>
	);
};


export default navigation;