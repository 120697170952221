import {
	GET_NODE_GROUP_LIST_START,
	GET_NODE_GROUP_LIST_SUCCESS,
	GET_NODE_GROUP_LIST_FAIL,

	GET_NODE_GROUP_START,
	GET_NODE_GROUP_SUCCESS,
	GET_NODE_GROUP_FAIL,

	CREATE_NODE_GROUP_START,
	CREATE_NODE_GROUP_SUCCESS,
	CREATE_NODE_GROUP_FAIL,

	DELETE_NODE_GROUP_START,
	DELETE_NODE_GROUP_SUCCESS,
	DELETE_NODE_GROUP_FAIL,

	GET_NODE_GROUP_OPTIONS_START,
	GET_NODE_GROUP_OPTIONS_SUCCESS,
	GET_NODE_GROUP_OPTIONS_FAIL,
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	nodeGroup: {},
	nodeGroupList: {},
	nodeGroupOptions: {},
	nodeGroupIsLoading: false,
	nodeGroupListIsLoading: false,
	nodeGroupOptionsIsLoading: false,
	isCreating: false,
	isDeleting: false
};

/* node group list start */
const getNodeGroupListStart = (state) => {
	return utils.updateObject(state, {
		nodeGroupListIsLoading: true
	});
};

const getNodeGroupListSuccess = (state, action) => {
	return utils.updateObject(state, {
		nodeGroupList: action.nodeGroupList,
		nodeGroupListIsLoading: false
	});
};

const getNodeGroupListFail = (state) => {
	return utils.updateObject(state, {
		nodeGroupList: [],
		nodeGroupListIsLoading: false
	});
};
/* node group list end */

/* node group start */
const getNodeGroupStart = (state) => {
	return utils.updateObject(state, {
		nodeGroupIsLoading: true
	});
};

const getNodeGroupSuccess = (state, action) => {
	return utils.updateObject(state, {
		nodeGroup: action.nodeGroup,
		nodeGroupIsLoading: false
	});
};

const getNodeGroupFail = (state) => {
	return utils.updateObject(state, {
		nodeGroup: {},
		nodeGroupIsLoading: false
	});
};
/* node group end */

/* node group create/edit start */
const createNodeGroupStart = (state) => {
	return utils.updateObject(state, {
		isCreating: true
	});
};

const createNodeGroupSuccess = (state, action) => {
	return utils.updateObject(state, {
		nodeGroup: action.nodeGroup,
		isCreating: false
	});
};

const createNodeGroupFail = (state) => {
	return utils.updateObject(state, {
		isCreating: false
	});
};
/* node group create/edit end */

/* node group delete start */
const deleteNodeGroupStart = (state) => {
	return utils.updateObject(state, {
		isDeleting: true
	});
};

const deleteNodeGroupSuccess = (state, action) => {
	return utils.updateObject(state, {
		nodeGroupList: action.nodeGroupList,
		isDeleting: false
	});
};

const deleteNodeGroupFail = (state) => {
	return utils.updateObject(state, {
		isDeleting: false
	});
};
/* node group delete end */

/* node group options start */
const getNodeGroupOptionsStart = (state) => {
	return utils.updateObject(state, {
		nodeGroupOptions: {},
		nodeGroupOptionsIsLoading: true
	});
};

const getNodeGroupOptionsSuccess = (state, action) => {
	return utils.updateObject(state, {
		nodeGroupOptions: action.nodeGroupOptions,
		nodeGroupOptionsIsLoading: false
	});
};

const getNodeGroupOptionsFail = (state) => {
	return utils.updateObject(state, {
		nodeGroupOptions: {},
		nodeGroupOptionsIsLoading: false
	});
};
/* node group options end */


const nodeGroupReducer = (state = initialState, action = {}) => {
	switch (action.type) {
	case GET_NODE_GROUP_LIST_START: return getNodeGroupListStart(state, action);
	case GET_NODE_GROUP_LIST_SUCCESS: return getNodeGroupListSuccess(state, action);
	case GET_NODE_GROUP_LIST_FAIL: return getNodeGroupListFail(state, action);

	case GET_NODE_GROUP_START: return getNodeGroupStart(state, action);
	case GET_NODE_GROUP_SUCCESS: return getNodeGroupSuccess(state, action);
	case GET_NODE_GROUP_FAIL: return getNodeGroupFail(state, action);

	case CREATE_NODE_GROUP_START: return createNodeGroupStart(state, action);
	case CREATE_NODE_GROUP_SUCCESS: return createNodeGroupSuccess(state, action);
	case CREATE_NODE_GROUP_FAIL: return createNodeGroupFail(state, action);

	case DELETE_NODE_GROUP_START: return deleteNodeGroupStart(state, action);
	case DELETE_NODE_GROUP_SUCCESS: return deleteNodeGroupSuccess(state, action);
	case DELETE_NODE_GROUP_FAIL: return deleteNodeGroupFail(state, action);

	case GET_NODE_GROUP_OPTIONS_START: return getNodeGroupOptionsStart(state, action);
	case GET_NODE_GROUP_OPTIONS_SUCCESS: return getNodeGroupOptionsSuccess(state, action);
	case GET_NODE_GROUP_OPTIONS_FAIL: return getNodeGroupOptionsFail(state, action);

	default:
		return state;
	}
};

export default nodeGroupReducer;