import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { getSubnetList, deleteSubnetById } from '../../store/actions';
import moment from 'moment';
import utils from '../../services/utils';

import Loader from '../../components/UI/Loader/Loader';
import ConfirmModal from '../../components/UI/ConfirmModal/ConfirmModal';
import './SubnetList.css';

const PER_PAGE = process.env.REACT_APP_PAGE_SIZE;

class SubnetList extends Component {
	state = {
		deletingSubnet: {},
		search: '',
		urlParams: '',
		currentCursor: null,
		activePage: 1
	};

	componentDidMount() {
		document.title = 'Subnets - ' + process.env.REACT_APP_PAGE_TITLE;
		const cursor = (this.props.location && this.props.location.state && this.props.location.state.currentCursor) || null;
		this.setState({currentCursor: cursor}, () => {
			this.props.onGetSubnetList(null, (cursor || 'initial'), PER_PAGE);
		});
		document.addEventListener('keydown', this.handleKeyPress, false);
	}

	componentWillUnmount(){
		document.removeEventListener('keydown', this.handleKeyPress, false);
	}

	handleKeyPress = e => {
		const {search} = this.state;

		if(search) {
			if(e.keyCode === 27) {
				this.setState({search: ''}, () => {
					this.handleSearchBy();
				});
			} else if(e.keyCode === 13) {
				this.handleSearchBy();
			}
		}
	};

	handlePageChange = page => {
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const pageParams = utils.processPageChange(currentCursor, urlParams, page);

		this.setState(pageParams, () => {
			this.props.onGetSubnetList(pageParams.urlParams, pageParams.currentCursor, PER_PAGE);
		});
	};

	handleOrderBy = sortBy => {
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const orderByParams = utils.processOrderBy(currentCursor, urlParams, sortBy);

		this.setState(orderByParams, () => {
			this.props.onGetSubnetList(orderByParams.urlParams, orderByParams.currentCursor, PER_PAGE);
		});
	};

	handleSearchBy = () => {
		const {search} = this.state;
		let currentCursor = this.state.currentCursor;
		let urlParams = this.state.urlParams;
		const searchByParams = utils.processSearchBy(currentCursor, urlParams, search);

		this.setState({ ...searchByParams, activePage: 1 }, () => {
			this.props.onGetSubnetList(searchByParams.urlParams, searchByParams.currentCursor, PER_PAGE);
		});
	};

	handleSearchChange = event => {
		const search = event.target.value;
		this.setState({search}, () => {
			if(!search) {
				this.handleSearchBy();
			}
		});
	};

	handleDeleteSubnetById = subnet => {
		this.setState({deletingSubnet: subnet});
	};

	handleCloseModal = () => {
		this.setState({deletingSubnet: {}});
	};

	handleConfirmModal = () => {
		const { deletingSubnet } = this.state;
		this.props.onDeleteSubnetById(deletingSubnet.snid).then(() => {
			this.setState({deletingSubnet: {}});
		});
	};

	render() {
		const { subnetList, loading, isSubnetDeleting } = this.props;
		const { deletingSubnet, search, urlParams, activePage } = this.state;
		const pageCount = subnetList.count || 1;
		let _subnetList = (subnetList && subnetList.results) ? [ ...subnetList.results ] : [];

		return (
			<div className="container-fluid">
				<div className="card shadow">
					<div className="card-header py-3">
						<h1 className="h3 text-gray-800">Subnet</h1>
						<NavLink exact to="/subnet/new" className="float-right">Create</NavLink>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-sm-12 col-md-6">
								<div className="input-group">
									<input type="text"
										value={search}
										className="form-control bg-light border-0 small"
										placeholder="Search for..."
										aria-label="Search"
										aria-describedby="basic-addon2"
										onChange={this.handleSearchChange}/>
									<div className="input-group-append">
										<button className="btn btn-primary" type="button" onClick={this.handleSearchBy}>
											<i className="fas fa-search fa-sm"></i>
										</button>
									</div>
								</div>
								<p className="search-field-info">Search performed through all pages within all text fields.</p>
							</div>
							<div className="col-sm-12 col-md-6"></div>
						</div>

						<div className="row">
							{loading ?
								<Loader /> :
								<div className="col-sm-12 dataTables_container">
									<div className="dataTables_wrapper">
										<table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing="0" style={{width: '100%'}}>
											{_subnetList.length ?
												<Fragment>
													<thead>
														<tr role="row">
															<th className={`sorting${(urlParams && urlParams.indexOf('cidr') > -1) ? (urlParams.indexOf('-cidr') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('cidr')} style={{width: '25%'}}>CIDR</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('prefix') > -1) ? (urlParams.indexOf('-prefix') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('prefix')} style={{width: '15%'}}>Prefix</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('aton_st') > -1) ? (urlParams.indexOf('-aton_st') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('aton_st')} style={{width: '25%'}}>Aton Start</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('aton_en') > -1) ? (urlParams.indexOf('-aton_en') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('aton_en')} style={{width: '25%'}}>Aton End</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('created') > -1) ? (urlParams.indexOf('-created') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('created')} style={{minWidth: '90px'}}>Created</th>
															<th className={`sorting${(urlParams && urlParams.indexOf('modified') > -1) ? (urlParams.indexOf('-modified') > -1 ? '_asc' : '_desc') : ''}`} onClick={() => this.handleOrderBy('modified')} style={{minWidth: '90px'}}>Modified</th>
															<th></th>
														</tr>
													</thead>
													<tfoot>
														<tr>
															<th>CIDR</th>
															<th>Prefix</th>
															<th>Aton Start</th>
															<th>Aton End</th>
															<th>Created</th>
															<th>Modified</th>
															<th></th>
														</tr>
													</tfoot>
												</Fragment> : null
											}
											<tbody>
												{_subnetList.map(item => (
													<tr role="row" className="odd" key={item.snid}>
														<td className="sorting_1"><NavLink exact to={`/subnet/${item.snid}`}>{item.cidr || 'N/A'}</NavLink></td>
														<td>{item.prefix || 'N/A'}</td>
														<td>{item.aton_st || 'N/A'}</td>
														<td>{item.aton_en || 'N/A'}</td>
														<td>{item.created ? moment(1000*item.created).format('YYYY-MM-DD') : 'N/A'}</td>
														<td>{item.modified ? moment(1000*item.modified).format('YYYY-MM-DD') : 'N/A'}</td>
														<td>
															<div className="action-wrapper">
																<NavLink exact to={`/subnet/${item.snid}`} className="float-right"><i className="fas fa-edit"></i></NavLink>
																<i className="fas fa-trash" onClick={() => this.handleDeleteSubnetById(item)}></i>
															</div>
														</td>
													</tr>
												))}
												{_subnetList.length === 0 ?
													<tr role="row" className="odd">
														<td colSpan="7">
															<div className="empty-list">List is empty</div>
														</td>
													</tr> : null}
											</tbody>
										</table>
									</div>
								</div>
							}
						</div>
						{loading || _subnetList.length === 0 ?
							null :
							<div className="row">
								<div className="col-sm-12 col-md-5"></div>
								<div className="col-sm-12 col-md-7">
									<div className="dataTables_paginate paging_simple_numbers">
										<Pagination
											innerClass="pagination float-right"
											itemClass="page-item"
											linkClass="page-link"
											activePage={activePage}
											itemsCountPerPage={PER_PAGE}
											totalItemsCount={pageCount}
											pageRangeDisplayed={5}
											onChange={this.handlePageChange}
										/>
									</div>
								</div>
							</div>
						}
					</div>
				</div>

				<ConfirmModal
					title="Delete Subnet"
					content={'Are you sure, you want to delete \'' + deletingSubnet.prefix + '\'' }
					showModal={deletingSubnet.snid || deletingSubnet.snid === 0}
					disabled={isSubnetDeleting}
					onCancel={this.handleCloseModal}
					onConfirm={this.handleConfirmModal}/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		subnetList: state.subnet.subnetList,
		loading: state.subnet.loading,
		isSubnetDeleting: state.subnet.isDeleting
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetSubnetList: (urlParams, cursor, perPage) => dispatch(getSubnetList(urlParams, cursor, perPage)),
		onDeleteSubnetById: id => dispatch(deleteSubnetById(id))
	};
};

SubnetList.propTypes = {
	location: PropTypes.object,
	subnetList: PropTypes.object,
	loading: PropTypes.bool,
	isSubnetDeleting: PropTypes.bool,
	onGetSubnetList: PropTypes.func,
	onDeleteSubnetById: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SubnetList);