import {
	GET_NODE_LIST_START,
	GET_NODE_LIST_SUCCESS,
	GET_NODE_LIST_FAIL,

	GET_NODE_START,
	GET_NODE_SUCCESS,
	GET_NODE_FAIL,

	CREATE_NODE_START,
	CREATE_NODE_SUCCESS,
	CREATE_NODE_FAIL,

	DELETE_NODE_START,
	DELETE_NODE_SUCCESS,
	DELETE_NODE_FAIL,

	GET_NODE_OPTIONS_START,
	GET_NODE_OPTIONS_SUCCESS,
	GET_NODE_OPTIONS_FAIL
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	nodeList: {},
	node: {},
	nodeOptions: {},
	loading: false,
	nodeIsLoading: false,
	isCreating: false,
	isDeleting: false,
	nodeOptionsIsLoading: false
};

/* node list start */
const getNodeListStart = (state) => {
	return utils.updateObject(state, {
		loading: true
	});
};

const getNodeListSuccess = (state, action) => {
	return utils.updateObject(state, {
		nodeList: action.nodeList,
		loading: false
	});
};

const getNodeListFail = (state) => {
	return utils.updateObject(state, {
		nodeList: [],
		loading: false
	});
};
/* node list end */

/* node start */
const getNodeStart = (state) => {
	return utils.updateObject(state, {
		nodeIsLoading: true
	});
};

const getNodeSuccess = (state, action) => {
	return utils.updateObject(state, {
		node: action.node,
		nodeIsLoading: false
	});
};

const getNodeFail = (state) => {
	return utils.updateObject(state, {
		node: {},
		nodeIsLoading: false
	});
};
/* node end */

/* node create/edit start */
const createNodeStart = (state) => {
	return utils.updateObject(state, {
		isCreating: true
	});
};

const createNodeSuccess = (state, action) => {
	return utils.updateObject(state, {
		node: action.node,
		isCreating: false
	});
};

const createNodeFail = (state) => {
	return utils.updateObject(state, {
		isCreating: false
	});
};
/* node create/edit end */

/* node delete start */
const deleteNodeStart = (state) => {
	return utils.updateObject(state, {
		isDeleting: true
	});
};

const deleteNodeSuccess = (state, action) => {
	return utils.updateObject(state, {
		nodeList: action.nodeList,
		isDeleting: false
	});
};

const deleteNodeFail = (state) => {
	return utils.updateObject(state, {
		isDeleting: false
	});
};
/* node delete end */

/* node options start */
const getNodeOptionsStart = (state) => {
	return utils.updateObject(state, {
		nodeOptions: {},
		nodeOptionsIsLoading: true
	});
};

const getNodeOptionsSuccess = (state, action) => {
	return utils.updateObject(state, {
		nodeOptions: action.nodeOptions,
		nodeOptionsIsLoading: false
	});
};

const getNodeOptionsFail = (state) => {
	return utils.updateObject(state, {
		nodeOptions: {},
		nodeOptionsIsLoading: false
	});
};
/* node options end */


const nodeReducer = (state = initialState, action = {}) => {
	switch (action.type) {
	case GET_NODE_LIST_START: return getNodeListStart(state, action);
	case GET_NODE_LIST_SUCCESS: return getNodeListSuccess(state, action);
	case GET_NODE_LIST_FAIL: return getNodeListFail(state, action);

	case GET_NODE_START: return getNodeStart(state, action);
	case GET_NODE_SUCCESS: return getNodeSuccess(state, action);
	case GET_NODE_FAIL: return getNodeFail(state, action);

	case CREATE_NODE_START: return createNodeStart(state, action);
	case CREATE_NODE_SUCCESS: return createNodeSuccess(state, action);
	case CREATE_NODE_FAIL: return createNodeFail(state, action);

	case DELETE_NODE_START: return deleteNodeStart(state, action);
	case DELETE_NODE_SUCCESS: return deleteNodeSuccess(state, action);
	case DELETE_NODE_FAIL: return deleteNodeFail(state, action);

	case GET_NODE_OPTIONS_START: return getNodeOptionsStart(state, action);
	case GET_NODE_OPTIONS_SUCCESS: return getNodeOptionsSuccess(state, action);
	case GET_NODE_OPTIONS_FAIL: return getNodeOptionsFail(state, action);

	default:
		return state;
	}
};

export default nodeReducer;
