import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import utils from '../../services/utils';

const NodeGroupRow = ({ fieldId, nodeGroup, fieldsOption}) => {
	let row = null;

	switch(fieldsOption[fieldId].type) {
	case 'choice':
		const choices = fieldsOption[fieldId].choices;
		const choicesObj = utils.arrayToObject(choices, 'value', 'display_name');
		row = <td>{choicesObj[nodeGroup[fieldId]] || 'N/A'}</td>;
		break;
	default:
		if(fieldId === 'name') {
			row = <td className="sorting_1"><NavLink exact to={`/nodegroup/${nodeGroup.id}`}>{nodeGroup.name || 'N/A'}</NavLink></td>;
		} else if(fieldId === 'is_enabled') {
			row = <td className="text-center">{(nodeGroup[fieldId] === false) ? null : <span className="icon"><i className="fa fa-check"></i></span>}</td>;
		} else  if(fieldId === 'nodes') {
			row = <td>{nodeGroup[fieldId] ? (nodeGroup[fieldId] || []).map(n => (<Fragment key={n.ndid}><NavLink exact to={`/node/${n.ndid}`}>{n.hostname}</NavLink><br /></Fragment>)) : 'N/A'}</td>;
		} else{
			row = <td>{nodeGroup[fieldId] || 'N/A'}</td>;
		}
	}

	return row;
};

export default NodeGroupRow;