import API from '../../services/api/api';
import utils from '../../services/utils';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';

const modules = {
	API,
	utils,
	Sentry,
	toast
};

export default modules;