import React from 'react';
import PropTypes from 'prop-types';

import './Toolbar.css';

const toolbar = ({ onLogout }) => {
	return (
		<div className="vpndb-navbar navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
			<button className="btn" type="button" onClick={onLogout}>
				<i className="fas fa-sign-out-alt fa-sm fa-fw text-gray-400"></i>
			</button>
		</div>
	);
};

toolbar.propTypes = {
	onLogout: PropTypes.func
};

export default toolbar;