import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getNodeGroupById, createNodeGroup, getNodeGroupOptions, getNodeList } from '../../store/actions';
import moment from 'moment';
import config from '../../services/config';
import utils from '../../services/utils';

import Loader from '../../components/UI/Loader/Loader';
import CustomSelect from '../../components/UI/CustomSelect/CustomSelect';
import CustomInput from '../../components/UI/CustomInput/CustomInput';
import CustomCheckbox from '../../components/UI/CustomCheckbox/CustomCheckbox';
import CustomTextArea from '../../components/UI/CustomTextArea/CustomTextArea';
import './NodeGroup.css';

const INITIALSTATE = {
	nodeGroupDetails: {
		id: null,
		is_enabled: true,
		nodes: []
	},

	errors: {},
	isTouched: {}
};

class NodeGroup extends Component {
	state = {...INITIALSTATE};

	componentDidMount() {
		document.title = 'Node Group - ' + process.env.REACT_APP_PAGE_TITLE;
		const { location: { pathname } } = this.props;
		const id = pathname.split('/nodegroup/')[1];
		if(id === 'new') {
			this.props.onGetNodeGroupOptions(null);
		} else {
			this.props.onGetNodeGroupById(id);
			this.props.onGetNodeGroupOptions(id);
		}
		this.props.onGetNodeList();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { location: { pathname } } = this.props;
		const id = pathname.split('/nodegroup/')[1];
		if(!nextProps.isCreating && nextProps.nodeGroup && nextProps.nodeGroup.id && (id !== 'new')) {
			const nodeGroupDetails = nextProps.nodeGroup;
			document.title = (nodeGroupDetails && (nodeGroupDetails.name + ' - ' + process.env.REACT_APP_PAGE_TITLE)) || ('Node Group - ' + process.env.REACT_APP_PAGE_TITLE);
			this.setState({nodeGroupDetails: {...nodeGroupDetails}});
		}
	}

	renderFields = (fields, nodeList) => {
		const { errors, isTouched } = this.state;
		return fields.filter(f => config.NODE_GROUP_READONLY_FIELDS.indexOf(f.id) === -1).map(f => {
			if(f.id === 'id' || f.id === 'created' || f.id === 'modified') {
				return null;
			}
			if(f.id === 'nodes') {f.type = 'array';}

			switch(f.type) {
			case 'choice':
				return (
					<div className="col-sm-12 col-md-6 col-lg-6" key={f.id}>
						<CustomSelect
							id={f.id}
							label={f.label}
							value={('' + this.state.nodeGroupDetails[f.id]) || -1}
							type={f.type}
							disabled={f.read_only}
							required={f.required}
							isTouched={isTouched}
							errors={errors}
							optionList={f.choices}
							onChange={this.handleFieldChange} />
					</div>
				);
			case 'boolean':
				return (
					<div className="col-sm-12 col-md-6 col-lg-6" key={f.id}>
						<CustomCheckbox
							id={f.id}
							label={f.label}
							value={this.state.nodeGroupDetails[f.id] || false}
							type={f.type}
							disabled={f.read_only}
							error_message={this.state.errors[f.id] || ''}
							required={f.required}
							isTouched={isTouched}
							errors={errors}
							onChange={this.handleFieldChange} />
					</div>
				);
			case 'array':
				return (
					<div key={f.id} className="nodes-container">
						<div className="col-sm-12 col-md-6 col-lg-6">
							<div className="form-group">
								<label>{f.label}</label>
								<div>
									{nodeList.length ?
										<select className={'form-control'}
											value={-1}
											onChange={e => this.handleAddNode(e, nodeList)}>
											<option value="-1" key={-1}>Select</option>
											{nodeList.filter(n => ((this.state.nodeGroupDetails[f.id] || []).map(ng => ng.ndid).indexOf(n.ndid) === -1)).map((n) => {
												return <option key={n.ndid} value={n.ndid}>{n.hostname}</option>;
											})}
										</select> : null
									}
								</div>
							</div>
						</div>

						<div className="col-sm-12 col-md-12 col-lg-12">
							<div className="form-group">
								{nodeList.filter(n => ((this.state.nodeGroupDetails[f.id] || []).map(ng => ng.ndid).indexOf(n.ndid) > -1)).map(n => (
									<div key={n.ndid} className="btn btn-primary btn-icon-split btn-sm node-item" onClick={() => this.handleDeleteNode(n.ndid)}>
										<span className="text">{n.hostname}</span>
										<span className="icon text-white-50">
											<i className="fas fa-trash"></i>
										</span>
									</div>
								))}
							</div>
						</div>
					</div>
				);
			case 'field':
			case 'string':
			case 'float':
			case 'integer':
			case 'decimal':
				return f.id === 'notes' || f.id === 'description' ?
					(
						<div className="col-lg-12" key={f.id}>
							<CustomTextArea
								id={f.id}
								label={f.label}
								value={this.state.nodeGroupDetails[f.id] || ''}
								type={f.type}
								disabled={f.read_only}
								max_length={(f.id === 'iata' ? 3 : f.max_length)}
								min_length={f.min_length}
								error_message={this.state.errors[f.id] || ''}
								required={f.required}
								isTouched={isTouched}
								errors={errors}
								onChange={this.handleFieldChange} />
						</div>
					) :
					(
						<div className="col-sm-12 col-md-6 col-lg-6" key={f.id}>
							<CustomInput
								id={f.id}
								label={f.label}
								value={(this.state.nodeGroupDetails[f.id] || this.state.nodeGroupDetails[f.id] === 0) ? this.state.nodeGroupDetails[f.id] : ''}
								type={f.type}
								disabled={f.read_only}
								max_length={(f.id === 'iata' ? 3 : f.max_length)}
								min_length={f.min_length}
								error_message={this.state.errors[f.id] || ''}
								required={f.required}
								isTouched={isTouched}
								errors={errors}
								onChange={this.handleFieldChange} />
						</div>
					);
			default:
				return null;
			}
		});
	};

	handleAddNode = (event, nodeList) => {
		const ndid = +event.target.value;
		const { nodeGroupDetails, nodeGroupDetails: {nodes} } = this.state;
		const _nodeGroupDetails = {...nodeGroupDetails, nodes: [...nodes, ...(nodeList.filter(n => n.ndid === ndid))]};
		this.setState({nodeGroupDetails: _nodeGroupDetails});
	};

	handleDeleteNode = id => {
		const { nodeGroupDetails, nodeGroupDetails: {nodes} } = this.state;
		const _nodes = [...nodes];
		var index = _nodes.map(ng => ng.ndid).indexOf(id);
		_nodes.splice(index, 1);

		const _nodeGroupDetails = {...nodeGroupDetails, nodes: _nodes};
		this.setState({nodeGroupDetails: _nodeGroupDetails});
	};

	handleFieldChange = (event, type) => {
		const { nodeGroupDetails, isTouched, errors } = this.state;
		const { nodeGroupOptions } = this.props;
		const id = event.target.id;
		let value = type === 'boolean' ? event.target.checked : event.target.value || '';
		const _errors = { ...errors };
		const _isTouched = {
			...isTouched,
			[id]: true
		};

		if(type === 'choice' && (+value === -1 || value === '')) {
			value = null;
		}

		if((type === 'integer' || type === 'float' || type === 'decimal') && !/^[0-9,.]*$/.test(value)) {
			return;
		}

		const _n = nodeGroupOptions || {};
		if(_n[id]) {
			if(_n[id]['required'] && (!value || (value + '').length === 0 || (+value === -1))) {
				_errors[id] = 'required';
			} else if(_n[id]['max_length'] && value.length && value.length > _n[id]['max_length']) {
				_errors[id] = 'max_length';
			}  else if(_n[id]['min_length'] && value.length && value.length < _n[id]['min_length']) {
				_errors[id] = 'min_length';
			} else {
				delete _errors[id];
			}
		}

		const _stateToUpdate = {
			isTouched: _isTouched,
			errors: _errors
		};

		_stateToUpdate.nodeGroupDetails = {...nodeGroupDetails, [id]: value};

		this.setState(_stateToUpdate);
	};

	handleCreateNodeGroup = (e, redirect, openNewBlank, duplicate) => {
		const { nodeGroupDetails, nodeGroupDetails: {nodes}, errors } = this.state;
		const { history, location: { pathname } } = this.props;
		const id = pathname.split('/nodegroup/')[1];
		if(Object.keys(errors).length === 0) {
			const dataToSend = { ...nodeGroupDetails, nodes: [...(nodes || []).map(n => n.ndid)] };

			this.props.onCreateNodeGroup(dataToSend, (id === 'new' ? null : id)).then(response => {
				if(response.status && response.status !== 200 && response.status !== 201) {
					return;
				}
				if(redirect) {
					history.push('/nodegroup');
				}
				if(openNewBlank) {
					history.push('/nodegroup/new');
					this.setState(duplicate ? {nodeGroupDetails: {...nodeGroupDetails, id: null}} : {...INITIALSTATE});
				}
			});
		}
	};

	render() {
		const { nodeGroupOptions, isLoading, isCreating, history, nodeList } = this.props;
		const {
			nodeGroupDetails: {
				id,
				name,
				created,
				modified
			},

			errors
		} = this.state;

		const fieldsObj = {...nodeGroupOptions};
		const fieldsArray = Object.keys(fieldsObj).map((f) => {
			return {
				...fieldsObj[f],
				id: f
			};
		});
		const groupedFields = utils.groupFields(fieldsArray.sort((a) => (a.required ? -1 : 1)), config.NODE_GROUP_FIELDS_GROUPING_PATTERN, 'id');

		const editMode = id || id === 0;
		const buttonDisabled = isCreating || Object.keys(errors).length || utils.isRequiredFieldsEmpty(fieldsArray, this.state.nodeGroupDetails, 'id');
		const _nodeList = ((nodeList && nodeList.results) || []).map(n => ({ndid: n.ndid, hostname: n.hostname}));

		return (
			<Fragment>
				{isLoading ?
					<div className="loader-wrapper">
						<Loader />
					</div> :
					<div className="content-wrapper">
						<div className="container-fluid fields-wrapper">
							<h1 className="h3 mb-2 text-gray-800">{isLoading ? '' : (name || 'Create New Node Group')}</h1>

							<div className="row mb-4 readonly-fields-container">
								{editMode && created ? <p className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">Created: </span>{moment(1000*created).format('YYYY-MM-DD HH:mm')}</p> : null}
								{editMode && modified ? <p className="col-sm-12 col-lg-6 mb-1 text-gray-800"><span className="label">Modified: </span>{moment(1000*modified).format('YYYY-MM-DD HH:mm')}</p> : null}
							</div>

							{groupedFields.map(g => {
								return g.fields.length ? (
									<div className="row group-fields" key={g.id}>
										{g.title ? <h4 className="col-lg-12 mb-0">{g.title}</h4> : null}
										{this.renderFields(g.fields.filter(f => (!editMode || (editMode && f.id !== 'name'))), _nodeList)} {/* Hide name on edit mode */}
									</div>
								) : null;
							})}
						</div>

						<div className="container-fluid">
							<div className="row">
								<div className="col-lg-12">
									<button type="submit"
										className="btn btn-primary mb-2 float-left"
										onClick={() => history.push('/nodegroup')}>Back</button>
									<button type="submit"
										className="btn btn-primary mb-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateNodeGroup(e, true)}>Save</button>
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={this.handleCreateNodeGroup}>{editMode ? 'Save and continue editing' : 'Save and duplicate new'}</button>
									{editMode ?
										<button type="submit"
											className="btn btn-primary mb-2 mr-2 float-right"
											disabled={buttonDisabled}
											onClick={e => this.handleCreateNodeGroup(e, false, true, true)}>Save and duplicate new</button> : null
									}
									<button type="submit"
										className="btn btn-primary mb-2 mr-2 float-right"
										disabled={buttonDisabled}
										onClick={e => this.handleCreateNodeGroup(e, false, true)}>Save and add another</button>
								</div>
							</div>
						</div>
					</div>
				}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		nodeList: state.node.nodeList,
		nodeGroup: state.nodeGroup.nodeGroup,
		nodeGroupOptions: state.nodeGroup.nodeGroupOptions,
		isLoading: state.nodeGroup.nodeGroupIsLoading || state.nodeGroup.nodeGroupOptionsIsLoading,
		isCreating: state.nodeGroup.isCreating
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetNodeGroupById: id => dispatch(getNodeGroupById(id)),
		onGetNodeGroupOptions: id => dispatch(getNodeGroupOptions(id)),
		onCreateNodeGroup: (nodeGroupData, ID) => dispatch(createNodeGroup(nodeGroupData, ID)),
		onGetNodeList: () => dispatch(getNodeList())
	};
};

NodeGroup.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	nodeList: PropTypes.object,
	nodeGroupOptions: PropTypes.object,
	nodeGroup: PropTypes.object,
	isLoading: PropTypes.bool,
	isCreating: PropTypes.bool,
	onGetNodeList: PropTypes.func,
	onGetNodeGroupById: PropTypes.func,
	onCreateNodeGroup: PropTypes.func,
	onGetNodeGroupOptions: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeGroup);
